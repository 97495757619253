import { Injectable } from '@angular/core';
import { EventKey } from 'src/app/util/event-key';
import { Events } from 'src/app/util/Events';
import { GlobalVars } from 'src/app/util/global-vars';
import { Util } from 'src/app/util/util';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl;
  private authText;

  constructor(public http: HttpService, public storage: StorageService,
    public events: Events, public globalVars: GlobalVars) {
    this.baseUrl = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  getUserByEmail(email: string, successCallback: any, errorCallback: any) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers: {
        Authorization: 'Basic ' + btoa(this.authText)
      },
      params: { email: email }
    };

    this.http.get(this.baseUrl + '/rs/crud/buyers', config, successCallback, errorCallback);
  }

  createUser(userJsonObj: any, callBack) {
    this.authText = this.globalVars.getAuthText();

    let data = JSON.stringify(userJsonObj);

    let config = {
      headers: {
        Authorization: 'Basic ' + btoa(this.authText),
        'Content-Type': 'application/json',
        'X-CLIENT': this.globalVars.getXClient(),
        'X-RECAPTCHA-INFO': this.globalVars.getRecaptchaInfo()
      }
    };

    this.http.post(this.baseUrl + '/rs/crud/buyers/create', data, config,
      (data) => {
        callBack('sucess', data);
      },
      (error) => {
        callBack('fail', error);
      });
  }

  saveUser(userJsonObj: any, callBack) {
    this.authText = this.globalVars.getAuthText();

    let data = JSON.stringify(userJsonObj);

    let config = {
      headers: {
        Authorization: 'Basic ' + btoa(this.authText),
        'Content-Type': 'application/json'
      }
    };

    this.http.post(this.baseUrl + '/rs/crud/buyers', data, config,
      (data) => {
        callBack('sucess', data);
      },
      (error) => {
        callBack('fail', error);
      });
  }

  deleteUser(buyerId: any, callBack) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers: {
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.delete(this.baseUrl + '/rs/crud/buyers/'+buyerId, config,
      (data) => {
        callBack('sucess', data);
      },
      (error) => {
        callBack('fail', error);
      });
  }

  saveUserWithNewPassword(userJsonObj: any, callBack) {
    this.authText = this.globalVars.getAuthText();

    let data = JSON.stringify(userJsonObj);

    let config = {
      headers: {
        Authorization: 'Basic ' + btoa(this.authText),
        'Content-Type': 'application/json'
      }
    };

    this.http.post(this.baseUrl + '/rs/crud/buyers/updateWithPassword', data, config,
      (data) => {
        callBack('sucess', data);
      },
      (error) => {
        callBack('fail', error);
      });
  }

  authenticateUser(userJsonObj: any, callBack) {
    this.authText = this.globalVars.getAuthText();

    let data = JSON.stringify(userJsonObj);

    let config = {
      headers: {
        Authorization: 'Basic ' + btoa(this.authText),
        'Content-Type': 'application/json',
        'X-CLIENT': this.globalVars.getXClient(),
        'X-RECAPTCHA-INFO': this.globalVars.getRecaptchaInfo()
      }
    };

    this.http.post(this.baseUrl + '/rs/crud/buyers/authenticate', data, config,
      (data) => {
        callBack('sucess', data);
      },
      (error) => {
        callBack('fail', error);
      });

  }

  authenticateAdminUser(adminUserJsonObj: any, callBack) {
    //TODO-M
    /*
    let headers:Headers = Util.createAuthHeaders(this.globalVars.getAuthText());
    headers.append('X-CLIENT', this.globalVars.getXClient());
    headers.append('X-RECAPTCHA-INFO', this.globalVars.getRecaptchaInfo());

    let options = new RequestOptions({ headers: headers });

    this.http.post(this.globalVars.getBaseURL()+'/rs/crud/users/authenticate', adminUserJsonObj, options)
      .subscribe(data => {
        let jsonData = JSON.parse(data['_body']);

        callBack('sucess',jsonData);
       }, error => {
        console.error(error);
        callBack('error',error);
      });
      */
  }

  authWithSocialNetwork(userJsonObj: any, callBack) {
    //TODO-M
    /*
    let options = new RequestOptions({ headers: Util.createAuthHeaders(this.globalVars.getAuthText()) });

    this.http.post(this.globalVars.getBaseURL()+'/rs/crud/buyers/authWithSocialNetwork', userJsonObj, options)
      .subscribe(data => {

        let jsonData = JSON.parse(data['_body']);

        callBack('sucess',jsonData);
       }, error => {
        console.error(error);
        callBack('error',error);
      });
      */
  }

  resetPasswordUser(email: string, callBack) {
      this.authText = this.globalVars.getAuthText();

      let config = {
        headers: {
          Authorization: 'Basic ' + btoa(this.authText),
          'Content-Type': 'application/json'
        }
      };
  
      this.http.post(this.baseUrl +'/rs/config/resetPassword/buyer', {email:email}, config,
        (data) => {
          callBack('sucess', data);
        },
        (error) => {
          callBack('fail', error);
        });
  }

  /*TODO:IMPORTANTE EssE método deve receber um callback pois o set do storage retorna 
  um promise e a operacao pode sofrer delay e isso pode dar problema na execucao do cósigo*/
  storageLocalUser(userObj: any) {

    let value: any = (userObj) ? JSON.stringify(userObj) : null;

    this.storage.set(Util.USER_STORAGE_KEY, value);
    this.events.publish(EventKey.USER_STORAGE_CHANGED, userObj);

    this.globalVars.setBuyerObj(userObj);
  }

  /**TODO: Analisar o uso desse metodo em todo o projeto e usbistituir por GlobalVars ja 
   * temos sempre disponivel lá, o buyerObj ou userObj */
  getStoragedLocalUser(callBack: any) {

    this.storage.get(Util.USER_STORAGE_KEY).then((userJsonString) => {

      if (userJsonString != undefined || userJsonString != null) {
        callBack(userJsonString);
      } else {
        callBack(null);
      }

    }, (errorData) => {
      console.error(JSON.parse(errorData));
    });
  }

  getStoragedLocalUserObj(callBack: any) {

    this.storage.get(Util.USER_STORAGE_KEY).then((userJsonString) => {

      if (userJsonString != undefined || userJsonString != null) {
        callBack(JSON.parse(userJsonString));
      } else {
        callBack(null);
      }

    }, (errorData) => {
      console.error(JSON.parse(errorData));
    });
  }

}
