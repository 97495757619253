import { Injectable } from '@angular/core';
import { GlobalVars } from 'src/app/util/global-vars';
import { Util } from 'src/app/util/util';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  baseURL:string;
  authText:string;

  constructor(public http: HttpService,public storage:StorageService, public globalVars:GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }


  getClient(successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.get(this.baseURL +'/rs/crud/clientes/one', config, successCallback, errorCallback);
  }

  getClientById(id:number,successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.get(this.baseURL +'/rs/crud/clientes/'+id, config, successCallback, errorCallback);
  }

  getChildren(successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.get(this.baseURL +'/rs/crud/clientes/one/children', config, successCallback, errorCallback);
  }

  /**Retorna o usuário a do sistema para o client do aplicativo*/
  getUserClient(successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.get(this.baseURL +'/rs/crud/users/logged', config, successCallback, errorCallback);
  }

  getStoragedLocalClientObj(callBack:any){
    
    this.storage.get(Util.USER_CLIENT_STORAGE_KEY).then((clientJson) => {
      if(clientJson){
        callBack(JSON.parse(clientJson));
      }else{
        callBack(null);
      }
    },(errorData)=>{
      console.error(JSON.parse(errorData));
    });
  }

  getClientOperationStatus(successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.get(this.baseURL +'/rs/crud/clientes/operationStatus', config, successCallback, errorCallback);
  }
}