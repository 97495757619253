import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EventKey } from 'src/app/util/event-key';
import { GlobalVars } from 'src/app/util/global-vars';
import { HttpService } from './http.service';
//import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { StorageService } from './storage.service';
import { Events } from '../util/Events';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cartKey: string = 'cart';
  private productMap:Map<number,any> = new Map<number,any>();

  private vibrationTime:number = 20;
  private isNotIosPlatform:boolean = true;

  constructor(public http: HttpService,
    public storage: StorageService,
    public events: Events,
    //public vibration: Vibration,
    public platform: Platform,
    public globalVars: GlobalVars) {

    this.isNotIosPlatform = !this.platform.is('ios');
  }

  init(){
    this.storage.get('cart').then((cartJsonString) => {

      if(cartJsonString){
        let cartJsonObj = JSON.parse(cartJsonString);
        
        for(let product of cartJsonObj.productlist){
          this.productMap.set(product.id,product);
        }
      }
    });
  }
  
  addProduct(product: any, callBack){

    if(!this.productMap.get(product.id)){
      this.productMap.set(product.id,product);
      
      this.events.publish(EventKey.CART_PRODUCT_ADD, product);
      this.events.publish(EventKey.CART_UPDATE, this.productMap);
      this.events.publish('cart:product:add:'+product.id, product);

      if(this.isNotIosPlatform){
        //this.vibration.vibrate(this.vibrationTime);
      }

      callBack();

      this.storageCart();
    }else{
      this.updateProduct(product,(dataCallBack)=>{
        callBack();
      });
    }
  }

  removeProduct(product: any,callBack){
    
    if(this.productMap.get(product.id)){
      this.productMap.delete(product.id);
      
      this.events.publish(EventKey.CART_PRODUCT_REMOVE, product);
      this.events.publish(EventKey.CART_UPDATE, this.productMap);
      this.events.publish('cart:product:remove:'+product.id, product);
      if(this.isNotIosPlatform){
        //this.vibration.vibrate(this.vibrationTime);
      }
      callBack();

      this.storageCart();
    }
  }

  updateQtProduct(product: any, qtpedido: number, callBack){

    if(this.productMap.get(product.id)){
      this.productMap.get(product.id).qtpedido = qtpedido;

      this.events.publish('cart:product:update:'+product.id, this.productMap.get(product.id));
      this.events.publish(EventKey.CART_UPDATE, this.productMap);
      
      if(this.isNotIosPlatform){
        //this.vibration.vibrate(this.vibrationTime);
      }
      callBack();

      this.storageCart();
    }
  }

  updateProduct(product: any, callBack){

    if(this.productMap.get(product.id)){
      this.productMap.set(product.id,product);
      this.events.publish('cart:product:update:'+product.id, this.productMap.get(product.id));
      this.events.publish(EventKey.CART_UPDATE, this.productMap);
      
      if(this.isNotIosPlatform){
        //this.vibration.vibrate(this.vibrationTime);
      }
      
      callBack();

      this.storageCart();
    }
  }

  getCartJson(callBack){
    this.storage.get('cart').then((cartJsonString) => {
      if(cartJsonString == undefined || cartJsonString == null){
        callBack(null);
      }else{
        callBack(JSON.parse(cartJsonString));
      }
    });
  }

  getProductMap(callBack){
    callBack(this.productMap);
  }

  getProductList():Array<any>{

    let productList = new Array<any>();

    if (this.productMap != undefined && this.productMap != null && this.productMap.size > 0) {

      this.productMap.forEach((product: any, key: number) => {
        productList.push(product);
      });
    } 

    return productList;
  }

  existProductInCart(wantedProduct: any, callBack){
    callBack(this.productMap.get(wantedProduct.id));
  }

  removeCart(callBack){
    
    let that = this;

    that.storage.remove(that.cartKey).then((obj)=>{
      that.productMap = new Map<number,any>();
      that.events.publish(EventKey.CART_REMOVED);
      that.events.publish(EventKey.CART_UPDATE, that.productMap);

      callBack();
    });

    if(this.isNotIosPlatform){
      //this.vibration.vibrate(this.vibrationTime);
    }
  }

  private storageCart(){

    let clientId = (this.globalVars.getClientObj())?this.globalVars.getClientObj().id:null;

    this.storage.get(this.cartKey).then((cartJsonString) => {

        if(cartJsonString == undefined || cartJsonString == null){
            
          let iniJsonCart = {date:new Date(),
            status:"novo",
            clientid: clientId,
            productlist:new Array()};

          this.productMap.forEach((value: any, key: number) => {
            iniJsonCart.productlist.push(this.productMap.get(key));
          });

          this.storage.set(this.cartKey, JSON.stringify(iniJsonCart));
        }else{
          
          let cartJsonObj = JSON.parse(cartJsonString);

          cartJsonObj.productlist = new Array();
          cartJsonObj.clientid = clientId;

          this.productMap.forEach((value: any, key: number) => {
            cartJsonObj.productlist.push(this.productMap.get(key));
          });

          this.storage.set(this.cartKey, JSON.stringify(cartJsonObj));
        }
      });

  }

  updateProducts(productList:Array<any>){
    let that = this;
    let updated:boolean = false;

    productList.forEach(basicProduct => {

      if(that.productMap.get(basicProduct.id)){
        that.productMap.get(basicProduct.id).salePrice = basicProduct.salePrice;
        that.events.publish('cart:product:update:'+basicProduct.id, this.productMap.get(basicProduct.id));
        updated = true;
      }
      
    });

    if(updated){
      this.storageCart();
      this.events.publish(EventKey.CART_UPDATE, this.productMap);
    }
  }

}
