import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { MessageService } from 'src/app/services/message.service';
import { MessageTypeService } from 'src/app/services/message-type.service';
import { UserService } from 'src/app/services/user.service';
import { GlobalVars } from 'src/app/util/global-vars';
import { Util } from 'src/app/util/util';
import { StorageService } from 'src/app/services/storage.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.page.html',
  styleUrls: ['./contact-us.page.scss'],
})
export class ContactUsPage implements OnInit {

  contactUsForm:FormGroup;
  messageTypeList:Array<any>;
  buyer:any;
  userObj:any;
  clientObj:any;
  typeModel:any;

  displayMsgSucessFinish:boolean = false;
  displayWaitSendMessage:boolean = false;
  displayForm:boolean = true;

  selectOptions:{title:string,subTitle:string};

  constructor(public navCtrl: NavController,
    public alertController:AlertController,
    public formBuilder: FormBuilder,
    public messageProvider:MessageService, 
    public messageTypeProvider:MessageTypeService,
    public userService:UserService,
    public storage:StorageService,
    public platform: Platform,
    public globalVars:GlobalVars,
    public modalController:ModalController,
    public location:Location) {
      
      this.messageTypeList = [];
      this.selectOptions = {title:"",subTitle:"Motivo do Contato"};

      this.contactUsForm = formBuilder.group({
        name: new FormControl('',Validators.compose([
          Validators.minLength(2),
          Validators.maxLength(40),
          Validators.pattern('^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$'),
          Validators.required])),
        //TODO: Só aceitar letras minusculas
        email: new FormControl('',Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])),
        cellphone: new FormControl('',Validators.compose([
            Validators.minLength(10),
            Validators.maxLength(12),
            Validators.required])),
        type: new FormControl('',Validators.compose([Validators.required])),
        text: new FormControl('',Validators.compose([Validators.minLength(2),
          Validators.maxLength(300),
          Validators.required]))
        });

      this.getListMessageType();
  }

  ngOnInit() {
  }

  ionViewDidEnter(){
    this.globalVars.setPageLoaded('ContatcusPage',true);
    this.userObj = this.globalVars.getBuyerObj();
    this.clientObj = this.globalVars.getClientObj();
  }

  onClickMobilePhone(){
    let phoneNumber:string = Util.getCleanPhoneNumer(this.clientObj.mobilePhone);
    window.open('tel:'+phoneNumber);
  }

  getListMessageType(){
    let that = this;
    
    this.messageTypeProvider.getListMessageType((sucessObj)=>{
      if(sucessObj){
        that.messageTypeList = sucessObj;
      }

    },(errorObj)=>{
      console.error(errorObj);
    });
    
  }

  saveMessage(){
    let that = this;
    if(this.contactUsForm.valid){
      
      that.displayForm = false;
      that.displayMsgSucessFinish = false;
      that.displayWaitSendMessage = true;
      
      this.messageProvider.saveMessage(this.getMessageObj(),(type,obj)=>{
        if(type =='sucess'){
          
          that.displayWaitSendMessage = false;
          that.displayForm = false;
          that.displayMsgSucessFinish = true;

          setTimeout(() => {
            that.close();
          }, 3000);

        }else{
          that.displayMsgSucessFinish = false;
          that.displayWaitSendMessage = false;
          that.displayForm = true;

          console.error(obj);
        }

      });
    }else{
      Util.showMenssage(this.alertController,"Todos os campos devem ser preenchidos");
    }
  }

  getMessageObj():any{

    let contactUsObj = {
      name: this.contactUsForm.controls['name'].value,
      email: this.contactUsForm.controls['email'].value.toLowerCase(),
      cellphone: this.contactUsForm.controls['cellphone'].value,
	    text: this.contactUsForm.controls['text'].value,
	    messagetype: this.typeModel,
	    buyer: this.userObj
    }

    return contactUsObj;
  }

  close(){
    if(this.globalVars.isVisibleSplitPane()){
      this.modalController.dismiss();
    }else{
      this.location.back();
    }
  }

  openInNewTab() {
    Util.openWhatsApp(this.clientObj.mobilePhone);
  }

  getPhoneNumber():string{
    return this.globalVars.getClientObj().mobilePhone;
  }

  getWhatsAppUrl(){
    return Util.getWhatsappURL(this.getPhoneNumber(),null);
  }

}
