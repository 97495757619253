import { Injectable } from '@angular/core';
import { GlobalVars } from 'src/app/util/global-vars';
import { Util } from 'src/app/util/util';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  data: any;

  baseURL;
  authText;

  constructor(public http: HttpService, public globalVars:GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  loadAllCategory(successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.get(this.baseURL +'/rs/crud/productCategorys/all', config, successCallback, errorCallback);
  }

  getBasicCategory(successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.get(this.baseURL +'/rs/crud/productCategorys/basic', config, successCallback, errorCallback);

  }
}
