import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import * as moment from 'moment-timezone';
import 'moment/locale/pt-br';
import { NavigationDataService } from '../services/navigation-data.service';

export class Util {

  /** TODO: Refatorar levando essas variaveis para uma classe especifica para elas */
  public static USER_CLIENT_STORAGE_KEY = "userclient";
  public static SETTINGS_KEY = "settingsKey";
  public static USER_STORAGE_KEY = "userKey";
  public static NOTIFY_ME_LIST_STORAGE_KEY = "notifyMeListKey";
  public static LAST_NOTIFICATION_KEY = "lastNotificationKey";
  public static ONE_SIGNAL_PLAYER_KEY = "oneSignalPlayeriIdKey";
  public static CREDIT_CARD_LIST_KEY = "creditCardListKey";
  public static VENDEMAIS_LOGED_KEY = "vendemaisLoged";
  public static CLIENT_TYPE_APP = "1";
  public static CLIENT_TYPE_PWA = "2";

  constructor() { }

  //AppConfig
  /*
  public static createAuthHeaders(btoaText: string): any {
    
    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(btoaText)
      }
    };

    return config;
  }*/

  public static toDateFormat(date: any): string {
    moment.locale('pt-BR');
    return moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');
  }

  public static toDateTimeFormat(dateTime: any): string {
    moment.locale('pt-BR');
    return moment(dateTime).format('DD/MM/YYYY HH:mm');
  }

  public static showMenssage(alertController: AlertController, msg: string) {

    alertController.create({
      message: msg,
      buttons: ['OK']
    }).then(res => {
     res.present();
    });
  }

  static cpfIsValid(cpf: string) {

    if (!cpf) {
      return false;

    }

    let strCPF = cpf.replace(/\D+/g, '');

    let soma: number = 0;
    let resto: number;

    if (strCPF.length < 11)
      return false;

    if (strCPF == "00000000000")
      return false;

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    };

    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) {
      resto = 0
    };

    if (resto != parseInt(strCPF.substring(9, 10))) {
      return false
    };

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    };

    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) {
      resto = 0
    };

    if (resto != parseInt(strCPF.substring(10, 11))) {
      return false
    };

    return true;
  }

  public static isAvailabilityProduct(product: any): boolean {

    if (product.availability || product.availability > 0) {
      return true;
    } else if (product.products && product.products.length > 0) {
      for (let i = 0; i < product.products.length; i++) {
        if (product.products[i].availability > 0)
          return true;
      }
    }

    return false;
  }

  /**Considera o desconto contido no pedido informado passado no metodo */
  static getOrderTotal(order: any, freightValue: number, useFreightInterestCalculate: boolean): number {

    let orderTotal: number = 0;
    let wayPaymentInterestTotal: number = 0;
    let totalProducts: number = Util.getProductsTotalOrder(order);
    let valorFidelidadeUsado: number = (order.loyaltyValueUsed) ? order.loyaltyValueUsed : 0;
    //let wayPaymentDiscount: number = Util.getWayPaymentDiscountTotal(order);

    //totalProducts = (totalProducts - valorFidelidadeUsado) - wayPaymentDiscount;
    totalProducts = totalProducts - valorFidelidadeUsado;

    if (order && order.coupon) {
      if (order.coupon.currencyValue) {
        totalProducts = totalProducts - order.coupon.currencyValue;
      } else if (order.coupon.percentValue) {
        totalProducts = totalProducts - ((order.coupon.percentValue * totalProducts) / 100);
      }
    }

    if (useFreightInterestCalculate) {
      wayPaymentInterestTotal = Util.getWayPaymentInterestTotalWithFreight(order, freightValue);
    } else {
      wayPaymentInterestTotal = Util.getWayPaymentInterestTotal(order);
    }

    orderTotal = ((((totalProducts + wayPaymentInterestTotal)) + freightValue));

    return orderTotal;
  }

  static getOrderTotalForDiscount(order: any, wayPayment: any ,interestTotal: number, freightValue: number): number {

    let orderTotal: number = 0;

    let totalProducts: number = Util.getProductsTotalOrderByWayPayment(order, wayPayment);
    let total: number = totalProducts + interestTotal;
    let valorFidelidadeUsado: number = 0

    if (order.loyaltyValueUsed) {
      valorFidelidadeUsado = (order.loyaltyValueUsed >= total) ? total : order.loyaltyValueUsed;
    }

    total = total - valorFidelidadeUsado;

    if (order && order.coupon) {
      if (order.coupon.currencyValue) {
        if (total > order.coupon.currencyValue) {
          total = total - order.coupon.currencyValue
        }
      } else if (order.coupon.percentValue) {
        total = total - ((order.coupon.percentValue * total) / 100);
      }
    }

    orderTotal = total + freightValue;

    return orderTotal;
  }

  static getWayPaymentDiscountTotal(order: any): number {

    let wayPaymentDiscountTotal: number = 0;
    let valorFidelidadeUsado: number = 0;
    let productsTotalOrder: number = Util.getProductsTotalOrder(order);

    if (order.loyaltyValueUsed) {
      valorFidelidadeUsado = (order.loyaltyValueUsed >= productsTotalOrder) ? productsTotalOrder : order.loyaltyValueUsed;
    }

    if (order) {

      let wayPaymentDiscountPercent = Util.getWayPaymentDiscountPercent(order.wayPayment, Util.getProductsTotalBy(order.itemOrders));

      if (order.itemOrders && wayPaymentDiscountPercent > 0) {

        let productPriceTotal = 0;

        for (let item of order.itemOrders) {
          productPriceTotal = productPriceTotal + (item.amount * Util.calculateProductPrice(item.product));
        }

        wayPaymentDiscountTotal = ((productPriceTotal - valorFidelidadeUsado) * wayPaymentDiscountPercent) / 100;
      }
    }

    return wayPaymentDiscountTotal;
  }

  static getDiscountTotalForWayPayment(wayPayment, order): number {

    let wayPaymentDiscountTotal: number = 0;

    if (wayPayment && order) {

      let wayPaymentPercentDiscount = Util.getWayPaymentDiscountPercentBy(wayPayment, order);

      if (order.itemOrders && wayPaymentPercentDiscount > 0) {
        for (let item of order.itemOrders) {

          //Obter o preço do produto para fins de calculo, considerando se ele esta em promoção.
          let productPrice = Util.calculateProductPrice(item.product);
          let productDiscount = ((productPrice * wayPaymentPercentDiscount) / 100);

          //Se o produto esta em promoção
          if (item.product.currentPromotionalProduct) {

            //Se a forma de pagamento tem um desconto e permite aplicar desconto em produto com promoção.
            if (order.wayPayment.applyDiscountOnPromotion) {
              wayPaymentDiscountTotal = wayPaymentDiscountTotal + (item.amount * productDiscount); 
            }

          } else {
            wayPaymentDiscountTotal = wayPaymentDiscountTotal + (item.amount * productDiscount);
          }
        }
      }
    }

    return wayPaymentDiscountTotal;
  }

  static getWayPaymentDiscountPercentBy(wayPayment, order) {

    if(order && wayPayment){
      
      let discountPercentWayPayment = (wayPayment.discount)?wayPayment.discount:0;

      if(wayPayment.limitAmountForDiscount && wayPayment.limitAmountForDiscount > 0){

        let discountPercentLimit = (wayPayment.discountPercentageForPurchaseTotal)?wayPayment.discountPercentageForPurchaseTotal:0;
        let limitAmountForDiscount = (wayPayment.limitAmountForDiscount)?wayPayment.limitAmountForDiscount:0;

        return (Util.getProductsTotalOrder(order) >= limitAmountForDiscount)?discountPercentLimit:discountPercentWayPayment;
      }

      return discountPercentWayPayment;
    }
    
    return 0;
  }

  static getWayPaymentInterestTotal(order: any): number {

    let wayPaymentInterestTotal: number = 0;
    if (order) {
      let totalProducts: number = Util.getProductsTotalOrder(order);
      let wayPaymentInterestPercent = Util.getWayPaymentInterestPercent(order);
      let valorFidelidadeUsado: number = (order.loyaltyValueUsed) ? order.loyaltyValueUsed : 0;

      let wayPaymentDiscountTotal: number = Util.getWayPaymentDiscountTotal(order);

      totalProducts = totalProducts - valorFidelidadeUsado - wayPaymentDiscountTotal;

      if (order && order.coupon) {
        if (order.coupon.currencyValue) {
          totalProducts = totalProducts - order.coupon.currencyValue;
        } else if (order.coupon.percentValue) {
          totalProducts = totalProducts - ((order.coupon.percentValue * totalProducts) / 100);
        }
      }

      if (totalProducts && totalProducts > 0 && wayPaymentInterestPercent && wayPaymentInterestPercent > 0) {
        wayPaymentInterestTotal = (totalProducts * wayPaymentInterestPercent) / 100;
      }
    }

    return wayPaymentInterestTotal;
  }

  static getWayPaymentInterestTotalWithFreight(order: any, freightValue: number): number {

    let wayPaymentInterestTotal: number = 0;

    if (order) {
      let totalProducts: number = Util.getProductsTotalOrder(order);
      let valorFidelidadeUsado: number = (order.loyaltyValueUsed) ? order.loyaltyValueUsed : 0;
      let wayPaymentInterestPercent: number = Util.getWayPaymentInterestPercent(order);

      if (wayPaymentInterestPercent > 0) {
        let wayPaymentDiscountTotal: number = Util.getWayPaymentDiscountTotal(order);

        totalProducts = totalProducts - valorFidelidadeUsado - wayPaymentDiscountTotal;

        if (order && order.coupon) {
          if (order.coupon.currencyValue) {
            totalProducts = totalProducts - order.coupon.currencyValue;
          } else if (order.coupon.percentValue) {
            totalProducts = totalProducts - ((order.coupon.percentValue * totalProducts) / 100);
          }
        }

        if (totalProducts && totalProducts > 0 && wayPaymentInterestPercent && wayPaymentInterestPercent > 0) {
          wayPaymentInterestTotal = ((totalProducts + freightValue) * wayPaymentInterestPercent) / 100;
        }
      }
    }

    return (wayPaymentInterestTotal >= 0)?wayPaymentInterestTotal:0;
  }

  static getWayPaymentDiscountPercent(wayPayment: any, total: number) {

    if(wayPayment){
      
      let discountPercentWayPayment = (wayPayment.discount)?wayPayment.discount:0;

      if(wayPayment.limitAmountForDiscount && wayPayment.limitAmountForDiscount > 0){

        let discountPercentLimit = (wayPayment.discountPercentageForPurchaseTotal)?wayPayment.discountPercentageForPurchaseTotal:0;
        let limitAmountForDiscount = (wayPayment.limitAmountForDiscount)?wayPayment.limitAmountForDiscount:0;

        return (total >= limitAmountForDiscount)?discountPercentLimit:discountPercentWayPayment;
      }

      return discountPercentWayPayment;
    }
    
    return 0;
  }

  static getWayPaymentInterestPercent(order: any) {
    return (order.wayPayment && order.wayPayment.interest) ? order.wayPayment.interest : 0;
  }

  static getProductsTotal(order: any) {
    let totalProducts: number = 0;

    if (order && order.itemOrders) {
      for (let item of order.itemOrders) {
        totalProducts = totalProducts + (item.amount * Util.calculateProductPrice(item.product));
      }
    }

    return totalProducts;
  }

  /**Calcula total dos produtos no pedido considenrando o desconto da promoção e forma de pagamento. */
  static getProductsTotalOrder(order: any): number {

    let totalProducts: number = 0;

    if (order) {

      if (order.wayPayment) {

        let wayPaymentPercentDiscount = Util.getWayPaymentDiscountPercent(order.wayPayment, Util.getProductsTotalBy(order.itemOrders));

        if (order.itemOrders) {
          for (let item of order.itemOrders) {

            //Obter o preço do produto para fins de calculo, considerando se ele esta em promoção.
            let productPrice = Util.calculateProductPrice(item.product);

            //Se o produto esta em promoção
            if(item.product.currentPromotionalProduct){
              
              //Se a forma de pagamento tem um desconto e permite aplicar desconto em produto com promoção.
              if(order.wayPayment.applyDiscountOnPromotion && wayPaymentPercentDiscount > 0){
                let productPriceWithWayPaymentDiscount = productPrice - ((productPrice * wayPaymentPercentDiscount)/100);
                totalProducts = totalProducts + (item.amount * productPriceWithWayPaymentDiscount);
              }else{
                totalProducts = totalProducts + (item.amount * productPrice);
              }

            }else{

              //Se existe um desconto para a forma de pagamento deve ser aplicato aos produtos estando ou não em promoção.
              if(wayPaymentPercentDiscount > 0){
                let productPriceWithWayPaymentDiscount = productPrice - ((productPrice * wayPaymentPercentDiscount)/100);
                totalProducts = totalProducts + (item.amount * productPriceWithWayPaymentDiscount);
              }else{
                totalProducts = totalProducts + (item.amount * productPrice);
              }
            }
          }
        }
      
      //Se o pedido não tem ainda uma forma de pagamento.
      } else {
        if (order.itemOrders) {
          for (let item of order.itemOrders) {
            totalProducts = totalProducts + (item.amount * Util.calculateProductPrice(item.product));
          }
        }
      }

    }

    return totalProducts;
  }

  static getProductsTotalOrderByWayPayment(order: any, wayPayment: any): number {

    let totalProducts: number = 0;

    if (order) {

      if (wayPayment) {

        let wayPaymentPercentDiscount = Util.getWayPaymentDiscountPercent(wayPayment, Util.getProductsTotalBy(order.itemOrders));

        if (order.itemOrders) {
          for (let item of order.itemOrders) {

            //Obter o preço do produto para fins de calculo, considerando se ele esta em promoção.
            let productPrice = Util.calculateProductPrice(item.product);

            //Se o produto esta em promoção
            if(item.product.currentPromotionalProduct){
              
              //Se a forma de pagamento tem um desconto e permite aplicar desconto em produto com promoção.
              if(wayPayment.applyDiscountOnPromotion && wayPaymentPercentDiscount > 0){
                let productPriceWithWayPaymentDiscount = productPrice - ((productPrice * wayPaymentPercentDiscount)/100);
                totalProducts = totalProducts + (item.amount * productPriceWithWayPaymentDiscount);
              }else{
                totalProducts = totalProducts + (item.amount * productPrice);
              }

            }else{

              //Se existe um desconto para a forma de pagamento deve ser aplicato aos produtos estando ou não em promoção.
              if(wayPaymentPercentDiscount > 0){
                let productPriceWithWayPaymentDiscount = productPrice - ((productPrice * wayPaymentPercentDiscount)/100);
                totalProducts = totalProducts + (item.amount * productPriceWithWayPaymentDiscount);
              }else{
                totalProducts = totalProducts + (item.amount * productPrice);
              }
            }
          }
        }
      
      //Se o pedido não tem ainda uma forma de pagamento.
      } else {
        if (order.itemOrders) {
          for (let item of order.itemOrders) {
            totalProducts = totalProducts + (item.amount * Util.calculateProductPrice(item.product));
          }
        }
      }

    }

    return totalProducts;
  }


  static getProductsTotalBy(itens): number {

    let totalProducts: number = 0;

    if (itens) {
      for (let item of itens) {

        //Obter o preço do produto para fins de calculo, considerando se ele esta em promoção.
        let productPrice = Util.calculateProductPrice(item.product);

        totalProducts = totalProducts + (item.amount * productPrice);
      }
    }

    return totalProducts;
  }

  //TODO:ESSE METODO É O MAIS CORRETO PARA O CALCULO FINAL DO PEDIDO E ITENS DO PEDIDO. Essa logica deve ser levada para a ideia do objeto OrderModel
  public static updateItensOrder(order: any, useCashDiscountBaseValue: boolean, onlinePayment: boolean) {

    for (let itemOrder of order.itemOrders) {
      
      //TODO: Adicionado para evitar bug... Analisar pq acontece.
      if(!itemOrder)
        continue;

      let product = itemOrder.product;

      if (product.currentPromotionalProduct) {

        let productPricePromotion = 0;

        if (product.currentPromotionalProduct.discountPercentage &&
          product.currentPromotionalProduct.discountPercentage > 0) {

          productPricePromotion = product.salePrice - ((product.salePrice * product.currentPromotionalProduct.discountPercentage) / 100);
          itemOrder.promotionalDiscountPercentage = product.currentPromotionalProduct.discountPercentage;

        } else if (product.currentPromotionalProduct.price &&
          product.currentPromotionalProduct.price > 0) {

          productPricePromotion = product.currentPromotionalProduct.price;
          itemOrder.promotionalDiscountPercentage = (productPricePromotion * 100) / product.salePrice;
        }

        itemOrder.productPrice = product.salePrice;
        itemOrder.productSalePrice = productPricePromotion;
        itemOrder.promotionalPrice = productPricePromotion;
        itemOrder.productFinalSalePrice = productPricePromotion;
      } else {
        itemOrder.productSalePrice = product.salePrice;
        itemOrder.productFinalSalePrice = product.salePrice;
      }
    }
  }

  //TODO: ESSE METODO SE BENEFICIA DO METODO updateItensOrder QUE FAZ O CALCULO CORRETO DOS VALORE FINAIS DOS PRODUTOS NO PEDIDO.
  static getInterestPercent(order: any): number {
    let interestPercent: number = 0

    if (order.wayPayment && order.wayPayment.interest) {
      interestPercent = order.wayPayment.interest;
    } else if (order.wayPayment && order.wayPayment.parcelInterests && order.wayPayment.parcelInterests.length > 0) {
      for (let parcel of order.wayPayment.parcelInterests) {
        if (parcel.number == order.qtParcel) {
          interestPercent = parcel.interest;
          break;
        }
      }
    }

    return interestPercent;
  }

  //TODO: ESSE METODO SE BENEFICIA DO METODO updateItensOrder QUE FAZ O CALCULO CORRETO DOS VALORE FINAIS DOS PRODUTOS NO PEDIDO.
  static getProductsTotalCanceled(order: any) {
    let totalProducts: number = 0;

    if (order && order.itemOrders) {
      for (let item of order.itemOrders) {
        if (item.statusItemOrder && item.statusItemOrder == "Canceled") {
          totalProducts = totalProducts + (item.amount * item.productSalePrice);
        }
      }
    }

    return totalProducts;
  }

  //TODO: ESSE METODO SE BENEFICIA DO METODO updateItensOrder QUE FAZ O CALCULO CORRETO DOS VALORE FINAIS DOS PRODUTOS NO PEDIDO.
  static getProductsTotalConsideringDiscount(order: any) {
    let totalProducts: number = 0;

    if (order && order.itemOrders) {
      for (let item of order.itemOrders) {
        totalProducts = totalProducts + (item.amount * item.productSalePrice);
      }
    }

    return totalProducts;
  }

  //Retorna produdo com estoque e menor preço
  public static getProductAvailabilityWithLowerPrice(product: any): any {
    let lowerPrice = product;

    if (product.products && product.products.length > 0) {
      for (let lowerPriceGrid of product.products) {
        if (lowerPriceGrid.availability > 0) {
          lowerPrice = (lowerPriceGrid.salePrice < lowerPrice.salePrice) ? lowerPriceGrid : lowerPrice;
        }
      } if (!lowerPrice) {
        lowerPrice = product;
      }
    } else {
      lowerPrice = product;
    }
    return lowerPrice;
  }

  //TODO: Esse método deve ser unificado com calculateProductPriceWithCashDiscount
  public static calculateProductPrice(product: any): number {
    let productPrice: number = product.salePrice;

    if (product.currentPromotionalProduct &&
      product.currentPromotionalProduct.discountPercentage &&
      product.currentPromotionalProduct.discountPercentage > 0) {

      productPrice = product.salePrice - ((product.salePrice * product.currentPromotionalProduct.discountPercentage) / 100);
    } else if (product.currentPromotionalProduct &&
      product.currentPromotionalProduct.price &&
      product.currentPromotionalProduct.price > 0) {

      productPrice = product.currentPromotionalProduct.price;
    }

    return productPrice;
  }

  /** 
   * Retorna o produto com menor preço entre o referencia e a grade, dando prioridade 
   * aos produtos em promoção
  */
  public static getProductWithLowPriceOrPromotion(product): any {

    //Produto referencia inicia como selecionado;
    let selectedProduct: any = product;

    //Percorre a grade de produtos
    for (let prod of product.products) {

      //Se o item da grade tem estoque
      if (prod.availability && prod.availability > 0) {

        //Se o item esta em promoção
        if (prod.currentPromotionalProduct) {

          //Se o selecionado atual tb esta em promoção
          if (selectedProduct.currentPromotionalProduct && selectedProduct.availability
            && selectedProduct.availability > 0) {

            let selectedProductPrice = Util.calculateProductPrice(selectedProduct);
            let prodPrice = Util.calculateProductPrice(prod);
            selectedProduct = (prodPrice < selectedProductPrice) ? prod : selectedProduct;
          } else {
            //Se não o item é o selecionado ja que ele esta em promoção
            selectedProduct = prod;
          }

        } else {

          if (!(selectedProduct.currentPromotionalProduct && selectedProduct.availability &&
            selectedProduct.availability > 0)) {
            selectedProduct = prod;
          }

        }
      }
    }
    return selectedProduct;
  }

  static hasDiscount(wayPayment: any): boolean {
    return wayPayment && ((wayPayment.limitAmountForDiscount && wayPayment.limitAmountForDiscount > 0) ||
                              (wayPayment.discount && wayPayment.discount > 0));
  }

  static hasParcel(methodPayment: any): boolean {
    return (methodPayment.parcelInterests && methodPayment.parcelInterests.length > 0);
  }

  static getMaxParcel(methodPayment: any): number {
    let number: number = 0;
    for (let i = 0; i < methodPayment.parcelInterests.length; i++) {
      number = (methodPayment.parcelInterests[i].number > number) ? methodPayment.parcelInterests[i].number : number;
    }
    return number;
  }

  static containsPromotionalProducts(orderObj): boolean {
    for (let itemOrder of orderObj.itemOrders) {
      if (itemOrder.product.currentPromotionalProduct) {
        return true;
      }
    }
    return false;
  }

  static containOnesignalPlayer(oneSignalPlayerId, userObj, ownerObj): boolean {
    let contain: boolean = false;

    if (oneSignalPlayerId && userObj.oneSignalPlayers) {
      for (let oneSignalObj of userObj.oneSignalPlayers) {
        if (oneSignalObj.playerId == oneSignalPlayerId &&
          (oneSignalObj.owner && oneSignalObj.owner.id == ownerObj.id)) {
          return true;
        }
      }
    }

    return false;
  }

  /*TODO: Esse metodo foi criado com objetivo de criar uma copia de um objeto do projeto quando necessário. 
  Descobrir uma forma mais elegante de fazer isso.*/
  static copyCustomObject(object: any): any {
    let objString: string = JSON.stringify(object);
    let copyOfTheObj: any = JSON.parse(objString);
    return copyOfTheObj
  }

  static getImagePath(attachmentPath: string, url: string): string {

    if (attachmentPath && attachmentPath.trim().length > 0) {
      return url + "/" + (attachmentPath.replace('uploads/', 'uploads/ORIGINAL_'));
    }

    return null;
  }

  static titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  static weekDayStringToNumber(weekDay: string): number {
    switch (weekDay.toLowerCase()) {
      case 'monday': {
        return 1;
      } case 'tuesday': {
        return 2;
      } case 'wednesday': {
        return 3;
      } case 'thursday': {
        return 4;
      } case 'friday': {
        return 5;
      } case 'saturday': {
        return 6;
      } case 'sunday': {
        return 7;
      } default: {
        return 0;
      }
    }
  }

  static getUserFirstNameUser(userObj: any): string {
    if (userObj && userObj.name) {
      let names: string[] = userObj.name.split(" ");
      return names[0];
    }
    return null;
  }

  //Pega uma data padrao Br e converte para um objeto Date
  static brDateToDate(brDate: string): Date {
    var arrDataExclusao = brDate.split('/');
    var stringFormatada = arrDataExclusao[1] + '-' + arrDataExclusao[0] + '-' + arrDataExclusao[2];

    return new Date(stringFormatada);
  }

  static getAddressInLine(orderObj: any): string {

    if (!orderObj)
      return;

    let lineAddress: string = (orderObj.address.lineAddress) ? orderObj.address.lineAddress + ', ' : '';
    let number: string = (orderObj.address.number) ? orderObj.address.number + ', ' : '';
    let district: string = (orderObj.address.district) ? orderObj.address.district + ', ' : '';
    let city: string = (orderObj.address.city) ? orderObj.address.city + ', ' : '';
    let state: string = (orderObj.address.state) ? orderObj.address.state + ', ' : '';
    let zipCode: string = (orderObj.address.zipCode) ? orderObj.address.zipCode : '';

    return lineAddress + number + district + city + state + zipCode;
  }

  static getLongNameState(name: string): string {

    if (!name)
      return '';

    if (name.toUpperCase() === 'AC' || name.toLowerCase() === 'acre')
      return 'Acre';
    else if (name.toUpperCase() === 'AL' || name.toLowerCase() === 'alagoas')
      return 'Alagoas';
    else if (name.toUpperCase() === 'AP' || name.toLowerCase() === 'amapá')
      return 'Amapá';
    else if (name.toUpperCase() === 'BA' || name.toLowerCase() === 'bahia')
      return 'Bahia';
    else if (name.toUpperCase() === 'CE' || name.toLowerCase() === 'ceará')
      return 'Ceará';
    else if (name.toUpperCase() === 'DF' || name.toLowerCase() === 'distrito federal')
      return 'Distrito Federal';
    else if (name.toUpperCase() === 'ES' || name.toLowerCase() === 'espírito santo')
      return 'Espírito Sato';
    else if (name.toUpperCase() === 'GO' || name.toLowerCase() === 'goiás')
      return 'Goiás';
    else if (name.toUpperCase() === 'MA' || name.toLowerCase() === 'maranhão')
      return 'Maranhão';
    else if (name.toUpperCase() === 'MT' || name.toLowerCase() === 'mato grosso')
      return 'Mato Grosso';
    else if (name.toUpperCase() === 'MS' || name.toLowerCase() === 'mato grosso do sul')
      return 'Mato Grosso do Sul';
    else if (name.toUpperCase() === 'MG' || name.toLowerCase() === 'minas gerais')
      return 'Minas Gerais';
    else if (name.toUpperCase() === 'PA' || name.toLowerCase() === 'pará')
      return 'Pará';
    else if (name.toUpperCase() === 'PB' || name.toLowerCase() === 'paraiba')
      return 'Paraíba';
    else if (name.toUpperCase() === 'PR' || name.toLowerCase() === 'paraná')
      return 'Paraná';
    else if (name.toUpperCase() === 'PE' || name.toLowerCase() === 'pernambuco')
      return 'Pernambuco';
    else if (name.toUpperCase() === 'PI' || name.toLowerCase() === 'piaui')
      return 'Piauí';
    else if (name.toUpperCase() === 'RJ' || name.toLowerCase() === 'rio de janeiro')
      return 'Rio de Janeiro';
    else if (name.toUpperCase() === 'RN' || name.toLowerCase() === 'rio grande do norte')
      return 'Rio Grande do Norte';
    else if (name.toUpperCase() === 'RS' || name.toLowerCase() === 'rio grande do sul')
      return 'Rio Grande do Sul';
    else if (name.toUpperCase() === 'RO' || name.toLowerCase() === 'rondônia')
      return 'Rondônia';
    else if (name.toUpperCase() === 'RR' || name.toLowerCase() === 'roraima')
      return 'Roraima';
    else if (name.toUpperCase() === 'SC' || name.toLowerCase() === 'santa catarina')
      return 'Santa Catarina';
    else if (name.toUpperCase() === 'SP' || name.toLowerCase() === 'são paulo')
      return 'São Paulo';
    else if (name.toUpperCase() === 'SE' || name.toLowerCase() === 'sergipe')
      return 'Sergipe';
    else if (name.toUpperCase() === 'TO' || name.toLowerCase() === 'tocantins')
      return 'Tocantins';
    else return '';
  }

  static getShortNameState(name: string): string {

    if (!name)
      return '';

    if (name.toUpperCase() === 'AC' || name.toLowerCase() === 'acre')
      return 'AC';
    else if (name.toUpperCase() === 'AL' || name.toLowerCase() === 'alagoas')
      return 'AL';
    else if (name.toUpperCase() === 'AP' || name.toLowerCase() === 'amapá')
      return 'AP';
    else if (name.toUpperCase() === 'BA' || name.toLowerCase() === 'bahia')
      return 'BA';
    else if (name.toUpperCase() === 'CE' || name.toLowerCase() === 'ceará')
      return 'CE';
    else if (name.toUpperCase() === 'DF' || name.toLowerCase() === 'distrito federal')
      return 'DF';
    else if (name.toUpperCase() === 'ES' || name.toLowerCase() === 'espírito santo')
      return 'ES';
    else if (name.toUpperCase() === 'GO' || name.toLowerCase() === 'goiás')
      return 'GO';
    else if (name.toUpperCase() === 'MA' || name.toLowerCase() === 'maranhão')
      return 'MA';
    else if (name.toUpperCase() === 'MT' || name.toLowerCase() === 'mato grosso')
      return 'MT';
    else if (name.toUpperCase() === 'MS' || name.toLowerCase() === 'mato grosso do sul')
      return 'MS';
    else if (name.toUpperCase() === 'MG' || name.toLowerCase() === 'minas gerais')
      return 'MG';
    else if (name.toUpperCase() === 'PA' || name.toLowerCase() === 'pará')
      return 'PA';
    else if (name.toUpperCase() === 'PB' || name.toLowerCase() === 'paraiba')
      return 'PB';
    else if (name.toUpperCase() === 'PR' || name.toLowerCase() === 'paraná')
      return 'PR';
    else if (name.toUpperCase() === 'PE' || name.toLowerCase() === 'pernambuco')
      return 'PE';
    else if (name.toUpperCase() === 'PI' || name.toLowerCase() === 'piaui')
      return 'PI';
    else if (name.toUpperCase() === 'RJ' || name.toLowerCase() === 'rio de janeiro')
      return 'RJ';
    else if (name.toUpperCase() === 'RN' || name.toLowerCase() === 'rio grande do norte')
      return 'RN';
    else if (name.toUpperCase() === 'RS' || name.toLowerCase() === 'rio grande do sul')
      return 'RS';
    else if (name.toUpperCase() === 'RO' || name.toLowerCase() === 'rondônia')
      return 'RO';
    else if (name.toUpperCase() === 'RR' || name.toLowerCase() === 'roraima')
      return 'RR';
    else if (name.toUpperCase() === 'SC' || name.toLowerCase() === 'santa catarina')
      return 'SC';
    else if (name.toUpperCase() === 'SP' || name.toLowerCase() === 'são paulo')
      return 'SP';
    else if (name.toUpperCase() === 'SE' || name.toLowerCase() === 'sergipe')
      return 'SE';
    else if (name.toUpperCase() === 'TO' || name.toLowerCase() === 'tocantins')
      return 'TO';
    else return '';
  }

  /*Retorna em forma de objeto o parametro passado pela URL. 
  Ex: 
  URL: http://localhost:8100/#/home?pid=158563
  Chamada: getURLParameterValue(url,"pid");
  Retorno: {name:"pid",value:"158563"}
  */
  static getURLParameterValue(url: string, parameterName: string) {

    let urlParameter;

    if (url && parameterName && url.indexOf("?") > 0) {
      let splitURL = url.split("?");
      let s: any;

      for (s in splitURL) {
        let splitParams = splitURL[s].split("&");
        let i: any;

        for (i in splitParams) {
          let singleURLParam = splitParams[i].split('=');

          if (singleURLParam[0] == parameterName) {
            urlParameter = {
              name: parameterName,
              value: singleURLParam[1]
            };

            break;
          }
        }

      }
    }

    return urlParameter;
  }

  /*Retorna em forma de objeto o parametro passado pela URL. 
  Ex: 
  URL: http://localhost:8100/#/product/158563
  Chamada: getURLParameterValue(url,"pid");
  Retorno: {name:"pid",value:"158563"}
  */
  static getURLProductIdValue(url: string) {

    if (url && url.indexOf("#/product/") > 0) {
      let splitURL = url.split("/");
      let idProduct = splitURL[splitURL.length - 1];
      if(splitURL.length > 0 && !isNaN(Number(idProduct))){
        return idProduct;
      }
    }

    return null;
  }

  //TODO: Não esta funcinando corretamente para numero do tipo 5587988572641
  static formatPhoneNumber(str: string) {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{2})(\d{4})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = (match[1] ? '+55 ' : '')
      return [intlCode, '(', match[2], ') ', match[4], '-', match[4]].join('')
    }

    return null;
  }

  static getCleanPhoneNumer(phoneNumber: string): string {
    return phoneNumber.trim().replace("(", "").replace(")", "").replace("-", "").replace("_", "").replace(" ", "");
  }

  static openWhatsApp(mobilePhone: string) {
    var win = window.open(Util.getWhatsappURL(mobilePhone,''), '_blank');
    win.focus();
  }
  
  static getWhatsappURL(mobilePhone: string,msg: string){
    //let urlText = "http://api.whatsapp.com/send?1=pt_BR&phone=55" + Util.getCleanPhoneNumer(mobilePhone);
    let urlText = "https://wa.me/55" + Util.getCleanPhoneNumer(mobilePhone);
    if(msg && msg.trim().length > 0){
      return urlText + "?text="+msg.trim().replace(" ", "%20");
    }else{
      return urlText;
    }
  }

  static trim(text) {
    return text.replace(/^\s+|\s+$/gm, '');
  }

  static validateEmail(mail): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    return false
  }

  static openGatewayPage(settings, router: Router, navigationDataService:NavigationDataService, params) {

    if (settings && settings.paymentGateway) {
      if (settings.paymentGateway == 'PAGSEGURO') {
        navigationDataService.setData("PagSeguroPaymentMethodPage",params);
        router.navigate(['/pag-seguro-payment-method']);
      } else if (settings.paymentGateway == 'REDE') {
        navigationDataService.setData("RedePaymentMethodPage",params);
        router.navigate(['/rede-payment-method']);
      }
    } else {
        navigationDataService.setData("PagSeguroPaymentMethodPage",params);
        router.navigate(['/pag-seguro-payment-method']);
    }
  }

  static getOrderedCategoryList(categoryList) {

    let orderByDisplayOrder = false;

    for (let category of categoryList) {
      if (category.displayOrder && category.displayOrder > 0) {
        orderByDisplayOrder = true;
        break;
      }
    }

    if (orderByDisplayOrder) {
      categoryList.sort(function (a, b) {

        //Se zero então fica no final da lista. A ordenção só deve considerar a partir de 1
        if (a.displayOrder > 0) {
          if (a.displayOrder < b.displayOrder) return -1;
          if (a.displayOrder > b.displayOrder) return 1;
        } else {
          return 1;
        }
        return 0;
      });
    } else {
      categoryList.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }

    return categoryList;
  }

  static getNumberMoneyValue(textValue): number {
    return Number(textValue.toString().replace(",", "."));
  }

  static getLowerCaseTextWithoutAccents(text:string){
    text = text.toLowerCase();

    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    text = text.replace(new RegExp('[Ç]','gi'), 'c');

    return text;
  }

  static getCNPJCPFWithoutPoint(cnpj){
    return (cnpj)?cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-',''):"";
  }

  static getUnitMeasurementText(textValue): string {
    if ('UNIDADE' == textValue)
      return 'Unidade';
    if ('AMPOLA' == textValue)
      return 'Ampola';
    if ('BALDE' == textValue)
      return 'Balde';
    if ('BANDEJA' == textValue)
      return 'Bandeja';
    if ('BARRA' == textValue)
      return 'Barra';
    if ('BISNAGA' == textValue)
      return 'Bisnaga';
    if ('BLOCO' == textValue)
      return 'Bloco';
    if ('BOBINA' == textValue)
      return 'Bobina';
    if ('BOMBONA' == textValue)
      return 'Bonbona';
    if ('CAPSULA' == textValue)
      return 'Capsula';
    if ('CARTELA' == textValue)
      return 'Cartela';
    if ('CENTO' == textValue)
      return 'Cento';
    if ('CONJUNTO' == textValue)
      return 'Conjunto';
    if ('CENTIMETRO' == textValue)
      return 'Centimetro';
    if ('CAIXA' == textValue)
      return 'CAIXA';
    if ('CENTIMETRO_QUADRADO' == textValue)
      return 'cm²';
    if ('DISPLAY' == textValue)
      return 'Display';
    if ('DUZIA' == textValue)
      return 'Duzia';
    if ('EMBALAGEM' == textValue)
      return 'Embalagem';
    if ('FARDO' == textValue)
      return 'Fardo';
    if ('FOLHA' == textValue)
      return 'Folha';
    if ('FRASCO' == textValue)
      return 'Frasco';
    if ('GALÃO' == textValue)
      return 'Galão';
    if ('GARRAFA' == textValue)
      return 'Garrafa';
    if ('GRAMAS' == textValue)
      return 'Gramas';
    if ('JOGO' == textValue)
      return 'Jogo';
    if ('QUILOGRAMA' == textValue)
      return 'Kg';
    if ('KIT' == textValue)
      return 'Kit';
    if ('LATA' == textValue)
      return 'Lata';
    if ('LITRO' == textValue)
      return 'Litro';
    if ('METRO_QUADRADO' == textValue)
      return 'm²';
    if ('METRO_CUBICO' == textValue)
      return 'm³';
    if ('MILHEIRO' == textValue)
      return 'Milheiro';
    if ('MILILITRO' == textValue)
      return 'Mililitro';
    if ('MEGAWATT_HORA' == textValue)
      return 'MWh';
    if ('PACOTE' == textValue)
      return 'Pacote';
    if ('PALETE_1' == textValue)
      return 'Pallete';
    if ('PARES' == textValue)
      return 'Pares';
    if ('PECA' == textValue)
      return 'Peça';
    if ('POTE' == textValue)
      return 'Pote';
    if ('QUILATE' == textValue)
      return 'Quilate';
    if ('RESMA' == textValue)
      return 'Resma';
    if ('ROLO' == textValue)
      return 'Rolo';
    if ('SACO' == textValue)
      return 'Saco';
    if ('SACOLA' == textValue)
      return 'Sacola';
    if ('TAMBOR' == textValue)
      return 'Tambor';
    if ('TANQUE' == textValue)
      return 'Tanque';
    if ('TONELADA' == textValue)
      return 'Tonelada';
    if ('TUBO' == textValue)
      return 'Tubo';
    if ('VASILHAME' == textValue)
      return 'Vasilhame';
    if ('Vidro' == textValue)
      return 'Vidro';
  }
}
