export class Origin {
    public static MENU_EDIT_USER = "menu:openedituser";
    public static FIDELITY_VIEW_PAGE = "fidelityView";
    public static ORDER_LIST_PAGE = "orderList";
    public static CART_PAGE = "cart";
    public static PRODUCT_PAGE = "productPage";
    public static MENU = "menu";
    public static BONUS_INFO_PAGE = "bonusInfoPage";
    public static PRODUCT_CARD = "productCard";
    public static ENTRY_PAGE_FACEBOOK_LOGIN = "entryPageFacebookLogin";
    public static CART_PAGE_WHIT_FACEBOOK_LOGIN = "entryPageFacebookLogin";
    public static FREIGHT_PANEL = "freightPanel";
    public static HOME = "home";
    public static MENU_LOGIN_ADMIN_USER = "loginAdminUser";
}