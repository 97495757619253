import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, Platform, ModalController } from '@ionic/angular';
import { EventKey } from 'src/app/util/event-key';
import { Events } from 'src/app/util/Events';
import { GlobalVars } from 'src/app/util/global-vars';
import { Origin } from 'src/app/util/origin';

@Component({
  selector: 'app-about-app',
  templateUrl: './about-app.page.html',
  styleUrls: ['./about-app.page.scss'],
})
export class AboutAppPage implements OnInit {

  timeOut:any;
  tapCount:number = 0;
  isPwa:boolean;

  constructor(
    public navCtrl: NavController,
    public modalController: ModalController,
    public alertCtrl: AlertController,
    public globalVars:GlobalVars,
    public platform:Platform,
    public events: Events){
      
      this.isPwa = this.globalVars.isPwa();
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.globalVars.setPageLoaded('AboutAppPage',true);
  }

  tapEvent(event:any){
    console.log("Tap...");
    
    let that = this;

    that.tapCount++;

    if (that.timeOut) {
      clearTimeout(that.timeOut);
    }
    
    that.timeOut = setTimeout(function () {

      if(that.tapCount == 15){
        that.showPrompt();
      }else if(that.tapCount == 5){

        let text = "Platform: "+that.platform.platforms();

        if(that.platform.is('mobileweb')){
          text=text+" Mobileweb: true ";
        }
        
        if(that.platform.is('mobile')){
        text=text+" Mobile: true";
        }

        if(document.URL.indexOf('http://localhost:8080') >= 0 || document.URL.indexOf('https://localhost:8080') >= 0){
          text=text+" Localhost: true";
        }

        text=text+" InnerHeight: "+window.innerHeight;
        text=text+" Height: "+window.screen.height;
        text=text+" URL: "+document.URL;
        text=text+" PWA: "+(document.URL.startsWith('http://localhost:8100') || (document.URL.startsWith('https') && document.URL.indexOf('.ymbu.com.br') >= 0));
        
        alert(text);
      }

      that.tapCount = 0;
    }, 5000);
  }

  showPrompt() {
    let that = this;

    const prompt = this.alertCtrl.create({
      message: "Entre com a URL do WebAdmin (Ex: http://127.0.0.1:8080/vendemais )",
      inputs: [
        {
          name: 'URL',
          placeholder: 'URL'
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          handler: data => {
            console.log('Cancelar Clicado!!!');
          }
        },
        {
          text: 'Salvar',
          handler: data => {
            that.setTempUrl(data);
          }
        }
      ]
    }).then((alrt)=>{
      alrt.present();
    });

  }

  setTempUrl(tempURL:any){
    if(tempURL){
      this.globalVars.setBaseURL(tempURL.URL);
    }
  }

  close(){
    if(this.globalVars.isVisibleSplitPane()){
      this.modalController.dismiss();
    }else{
      this.navCtrl.back();
    }
  }

  update(){
    if(this.isPwa){
      this.navCtrl.pop();
      this.events.publish(EventKey.RELOAD_PWA_APP);
    }
  }

  logInAdmin() {
    let dataSend = { origin: Origin.MENU_LOGIN_ADMIN_USER };
    //TODO-M: this.navCtrl.push('EntryPage', dataSend);

    this.globalVars.setPageLoaded('EntryPage',false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "EntryPage");
  }

}
