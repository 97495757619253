import { Injectable } from '@angular/core';
import { GlobalVars } from '../util/global-vars';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { StorageService } from 'src/app/services/storage.service';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { Util } from '../util/util';

@Injectable({
  providedIn: 'root'
})
export class UsageStatisticsService {

  baseURL: string;
  authText: string;

  constructor(private http: HttpService, 
    private globalVars: GlobalVars,
    public userService: UserService,
    public storage:StorageService,
    public platform: Platform) {

    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  async sendUsageStatisticsWithAction(page: string, action: string, product: string, value: string) {
    this.authText = this.globalVars.getAuthText();

    let deviceUuid: any = (this.platform.is('android') || this.platform.is('ios')) ? await Device.getId() : null;

    this.userService.getStoragedLocalUser((userObj: any) => {

      let buyer: any;
      let platformName: string;

      if (this.platform.is('ios')) {
        platformName = "ios";
      } else if (this.platform.is('android')) {
        platformName = "android";
      } else if (this.globalVars.isPwa()) {
        platformName = "pwa";
      }

      if (userObj != null) {
        buyer = JSON.parse(userObj);
      }

      let objStatistics = {
        pageView: page,
        deviceUUID: (deviceUuid)?deviceUuid.identifier:null,
        oneSignalPlayerId: null,
        buyer: buyer,
        appVersion: this.globalVars.getAppVersionNumber(),
        platformName: platformName,
        action: action,
        product: product,
        value: value
      }

      this.storage.get(Util.ONE_SIGNAL_PLAYER_KEY).then((userId) => {

        if (userId) {
          objStatistics.oneSignalPlayerId = userId;
        }

        this.saveStatistics(objStatistics, (type, data) => {
          if (type == 'sucess') {
            console.log("Estatistica de uso enviado com sucesso!!");
          }
        });

      });
    });
  }

  async sendUsageStatistics(pageView: string) {

    this.authText = this.globalVars.getAuthText();

    if (pageView && pageView.trim().length > 0) {

      let deviceUuid: any = (this.platform.is('android') || this.platform.is('ios')) ? await Device.getId() : null;

      this.userService.getStoragedLocalUser((userObj: any) => {
        let buyer: any;
        let platformName: string;

        if (this.platform.is('ios')) {
          platformName = "ios";
        } else if (this.platform.is('android')) {
          platformName = "android";
        } else if (this.globalVars.isPwa()) {
          platformName = "pwa";
        }

        if (userObj != null) {
          buyer = JSON.parse(userObj);
        }

        let objStatistics = {
          pageView: pageView,
          deviceUUID: (deviceUuid)?deviceUuid.identifier:null,
          oneSignalPlayerId: null,
          buyer: buyer,
          appVersion: this.globalVars.getAppVersionNumber(),
          platformName: platformName
        }

        this.storage.get(Util.ONE_SIGNAL_PLAYER_KEY).then((userId) => {

          if (userId) {
            objStatistics.oneSignalPlayerId = userId;
          }

          this.saveStatistics(objStatistics, (type, data) => {
            if (type == 'sucess') {
              console.log("Estatistica de uso em " + pageView + " enviado com sucesso!!");
            }
          });

        });
      });
    }
  }

  saveStatistics(statisticsObj, callBack) {
    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers: {
        Authorization: 'Basic ' + btoa(this.authText),
        'X-CLIENT': this.globalVars.getXClient(),
        'X-RECAPTCHA-INFO': this.globalVars.getRecaptchaInfo()
      }
    };

    this.http.post(this.baseURL + '/rs/crud/appUsageStatisticss', statisticsObj, config,
      (data) => {
        callBack('sucess', data);
      },
      (error) => {
        callBack('error', error);
      });
  }
}
