import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, NavParams, Platform, ToastController } from '@ionic/angular';
import { FacebookModel } from 'src/app/models/facebook-model';
import { NavigationDataService } from 'src/app/services/navigation-data.service';
import { NotifyMeService } from 'src/app/services/notify-me.service';
import { OneSignalPlayerService } from 'src/app/services/one-signal-player.service';
import { UserService } from 'src/app/services/user.service';
import { CallBack } from 'src/app/util/callback';
import { EventKey } from 'src/app/util/event-key';
import { Events } from 'src/app/util/Events';
import { GlobalVars } from 'src/app/util/global-vars';
import { MasterPage } from 'src/app/util/master-page';
import { Origin } from 'src/app/util/origin';
import { Util } from 'src/app/util/util';
import { CreateUserPage } from '../create-user/create-user.page';
import { AddressListPage } from '../address-list/address-list.page';
import { StorageService } from 'src/app/services/storage.service';
import { AddressCreatePage } from '../address-create/address-create.page';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage extends MasterPage implements OnInit {

  entryForm: FormGroup;
  notCreatedUser: boolean = true;
  util: Util;
  orderObj: any;
  origin: string;
  emailcpf: string;
  senha: string;
  displayMsgErrorOnLoad: boolean = false;
  displayForm: boolean = true;
  errorMsg: string;
  oneSignalPlayerId: string;
  actualLocationAddress: any;
  callBack: any;
  showFacebookButton: boolean;
  facebookAuthErroMsg = "Não foi possível fazer sua autenticação com o Facebook.";
  captchaPassed: boolean;
  recaptchaPublicKey: string;

  buyerAuthentication: boolean = true;
  hideBackButton: boolean = false;

  modal;

  constructor(
    public navCtrl: NavController,
    public formBuilder: FormBuilder,
    public userService: UserService,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public storage: StorageService,
    public platform: Platform,
    public events: Events,
    public notifyMeProvider: NotifyMeService,
    public oneSiginalPlayerProvider: OneSignalPlayerService,
    //TODO-M: public facebookProvider:FacebookProvider,
    public globalVars: GlobalVars,
    public modalController: ModalController,
    public zone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    public navigationDataService:NavigationDataService) {


    super(loadingController, storage, platform);

    this.util = new Util();

    this.initialSetup();
  }

  private initialSetup() {
    let that = this;

    this.entryForm = this.formBuilder.group({
      emailcpf: new FormControl('', Validators.compose([Validators.required])),
      senha: new FormControl('', Validators.compose([
        Validators.minLength(4),
        Validators.required
      ]))
    });

    if (this.globalVars.getBuyerObj()) {
      this.notCreatedUser = false;
    } else {
      this.notCreatedUser = true;
    }

    if (this.origin == Origin.MENU_LOGIN_ADMIN_USER) {
      this.buyerAuthentication = false;
    } else {
      this.buyerAuthentication = true;
    }

    this.events.subscribe(EventKey.GET_GEO_LOCATION, (addressLocation) => {
      if (!that.actualLocationAddress) {
        that.actualLocationAddress = addressLocation;
      }
    });

    //TODO: O botão não é mais exibido no IOS pq a Apple exigiu tb colocar login com Apple
    this.showFacebookButton = !this.globalVars.isPwa() && !this.platform.is('ios');

    if (this.globalVars.useRecaptcha()) {
      this.recaptchaPublicKey = this.globalVars.getSettingsObj().recaptchaPublicKey;
    }
  }

  ngOnInit(): void {

    let navigationData = this.navigationDataService.getData("LoginPage");

    if(navigationData){
      this.origin = navigationData.origin;
      this.hideBackButton = navigationData.hideBackButton;
      this.orderObj = navigationData.orderObj;
      this.callBack = navigationData.callBack;
      this.actualLocationAddress = navigationData.actualLocationAddress;
      this.modal = navigationData.modal;
    }
  }

  ionViewDidEnter() {
    this.displayForm = true;
    this.displayMsgErrorOnLoad = false;
    this.globalVars.setPageLoaded('EntryPage', true);

    this.storage.get(Util.ONE_SIGNAL_PLAYER_KEY).then((userId) => {
      this.oneSignalPlayerId = userId;
    });
  }

  ionViewDidLeave() {
    this.events.publish(EventKey.CLOSE_ENTRY);
  }

  ionViewWillEnter() {
    //TODO: Usado principalmente pela Home para evitar problema com virtualScroll que fica em branco.
    this.events.publish(EventKey.OPEN_ENTRY);
  }

  ionViewWillLeave() { }
  ionViewWillUnload() { }

  close() {
    this.modalController.dismiss();
  }

  doRefresh(refrashEvent: any) {
    this.displayForm = true;
    this.displayMsgErrorOnLoad = false;
    refrashEvent.complete();
  }

  facebookAuth() {
    let that = this;

    let facebookCallBack: CallBack<FacebookModel> = {
      sucess(facebookModel: FacebookModel) {

        let buyerForCreate = {
          name: facebookModel.name,
          gender: facebookModel.gender,
          typeBuyer: 'Varejo',
          email: facebookModel.email,
          facebookId: facebookModel.id
        };

        that.userService.authWithSocialNetwork(buyerForCreate, (typeReturn, objReturn) => {
          if (typeReturn == 'sucess') {

            that.events.publish(EventKey.LOGIN, objReturn);

            if (that.origin == Origin.CART_PAGE && (!objReturn.cpf || !objReturn.celular)) {
              that.openEditUserPage(objReturn, (buyerObj) => {
                that.whenAuthenticate(buyerObj);
              });
            } else {
              that.whenAuthenticate(objReturn);
            }

          } else {
            Util.showMenssage(that.alertController, that.facebookAuthErroMsg);
            console.error(objReturn);
          }
        });
      },
      error(errorData: any) {
        Util.showMenssage(that.alertController, that.facebookAuthErroMsg);
        console.log(errorData);
      }
    };


    //TODO-M: this.facebookProvider.login(facebookCallBack);
  }

  authAction() {
    if (this.origin == Origin.MENU_LOGIN_ADMIN_USER) {
      this.authenticateAdminUser();
    } else {
      this.authenticateUser();
    }
  }

  async authenticateAdminUser() {

    if (this.formIsValid()) {


      let loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        message: "Autenticando"
      });
  
      loading.present();

      this.userService.authenticateAdminUser(this.getAuthenticateJsonUserObj(), (type, userObj) => {

        loading.dismiss();

        if (type == 'sucess') {

          this.whenAdminAuthenticate(userObj)

        } else {

          if (userObj['_body']) {
            this.errorMsg = JSON.parse(userObj['_body']).legalMessage;
            this.alertInfoErrorServer(this.errorMsg);
          } else {
            this.errorMsg = "Não foi possível uma comunicação com nossos servidores. Verifique sua conexão de internet e tente novamente.";
            this.displayForm = false;
            this.displayMsgErrorOnLoad = true;
          }
        }
      });

    }
  }

  async authenticateUser() {
    let that = this;

    if (this.formIsValid()) {

      let loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        message: "Autenticando"
      });
  
      loading.present();

      this.userService.authenticateUser(this.getAuthenticateJsonObj(), (type, buyerObj) => {

        loading.dismiss();

        if (type == 'sucess') {

          this.whenAuthenticate(buyerObj);

        } else {

          if (buyerObj.data) {

            this.errorMsg = buyerObj.data.legalMessage;
            this.alertInfoErrorServer(this.errorMsg);
          } else {
            this.errorMsg = "Não foi possível uma comunicação com nossos servidores. Verifique sua conexão de internet e tente novamente.";
            this.displayForm = false;
            this.displayMsgErrorOnLoad = true;
          }
        }
      });
    }
  }

  private whenAdminAuthenticate(userObj: any) {
    //TODO-M:
    /*
    this.navCtrl.popToRoot().then(callBack=>{
      this.events.publish(EventKey.LOGIN_VENDEMAIS);
    });
    */
  }

  private whenAuthenticate(buyerObj: any) {

    this.globalVars.setBuyerObj(buyerObj);
    this.loadNotifyMeListInLocalStorage(buyerObj);

    if (this.origin == Origin.MENU_EDIT_USER) {
      let params:any = { userObj: buyerObj, 
        orderObj: this.orderObj, 
        origin: this.origin, 
        actualLocationAddress:this.actualLocationAddress };
      
      this.openCreateUserPage(params);
    } else {

      this.userService.storageLocalUser(buyerObj);
      this.saveOneSignalPlayerId();

      if (this.orderObj) {
        this.orderObj.buyer = buyerObj;
      }

      this.actionByOrigin(buyerObj);
    }
  }

  actionByOrigin(buyerObj) {

    if (this.origin == Origin.FIDELITY_VIEW_PAGE
      || this.origin == Origin.ORDER_LIST_PAGE
      || this.origin == Origin.PRODUCT_CARD
      || this.origin == Origin.MENU) {

      /*
      this.navCtrl.popToRoot().then(callBack => {
        this.events.publish(EventKey.LOGIN, buyerObj);
        if (this.origin == Origin.PRODUCT_CARD && this.callBack) {
          this.callBack(buyerObj);
        }
      });
      */

      //this.navCtrl.navigateBack('');
      this.events.publish(EventKey.LOGIN, buyerObj);

      if (this.origin == Origin.PRODUCT_CARD && this.callBack) {
        this.callBack(buyerObj);
      }

    } else if (this.origin == Origin.CART_PAGE) {
      this.events.publish(EventKey.LOGIN, buyerObj);
      this.callBack(buyerObj);
    } else if (this.origin == Origin.BONUS_INFO_PAGE) {
      //this.navCtrl.navigateBack('');
      this.events.publish(EventKey.OPEN_BONUS);
      this.events.publish(EventKey.LOGIN, buyerObj);
      /*
      this.navCtrl.popToRoot().then(callBack => {
        this.events.publish(EventKey.OPEN_BONUS);
        this.events.publish(EventKey.LOGIN, buyerObj);
      });
      */
    } else if (this.origin == Origin.PRODUCT_PAGE) {
      this.events.publish(EventKey.LOGIN, buyerObj);
      if (this.callBack) {
        this.callBack(buyerObj);
      }
    } else if (this.origin == Origin.HOME) {
      this.events.publish(EventKey.LOGIN, buyerObj);
      this.callBack(buyerObj);
    } else {
      this.events.publish(EventKey.LOGIN, buyerObj);
    }

    this.close();
  }

  saveOneSignalPlayerId() {
    this.oneSiginalPlayerProvider.saveOneSignalPlayerIdForBuyer(this.oneSignalPlayerId, (type, data) => {
      if (type == 'sucess') {
        console.log("OneSigalPlayerId salvo com sucesso!!");
      } else {
        console.error(data);
      }
    });
  }

  loadNotifyMeListInLocalStorage(buyerObj: any) {

    this.notifyMeProvider.getPedingNotifyMeList(buyerObj.id, (notifyMeList) => {

      if (notifyMeList) {

        this.notifyMeProvider.storageNotifyMeList(notifyMeList);
      }
    }, (errorData) => {
      console.error(errorData);
    });
  }

  getErrorMsg(): string {
    return this.errorMsg;
  }

  getAuthenticateJsonObj() {

    let authenticateJsonObj = {
      cpfemail: this.entryForm.controls['emailcpf'].value.toLowerCase(),
      password: this.entryForm.controls['senha'].value
    }

    return authenticateJsonObj;
  }

  getAuthenticateJsonUserObj() {

    let authenticateJsonObj = {
      username: this.entryForm.controls['emailcpf'].value.toLowerCase(),
      password: this.entryForm.controls['senha'].value
    }

    return authenticateJsonObj;
  }

  formIsValid() {

    if (this.entryForm.valid) {
      return true;
    } else {

      if (!this.entryForm.controls['emailcpf'].valid) {
        if (this.buyerAuthentication) {
          Util.showMenssage(this.alertController, "Informe um Email ou CPF");
        } else {
          Util.showMenssage(this.alertController, "Informe o usuário");
        }
      } else if (!this.entryForm.controls['senha'].valid || !this.entryForm.controls['confirmarsenha'].valid) {
        Util.showMenssage(this.alertController, "Informe sua senha");
      } else if (!this.emailcpf || (this.emailcpf && this.emailcpf.trim().length == 0)) {
        if (this.buyerAuthentication) {
          Util.showMenssage(this.alertController, "Informe um Email ou CPF");
        } else {
          Util.showMenssage(this.alertController, "Informe o usuário");
        }
      } else if (!this.senha || (this.senha && this.senha.trim().length == 0)) {
        Util.showMenssage(this.alertController, "Informe sua senha");
      }
    }

    return false;
  }

  async openNewAddressPage() {
    let that = this;

    let params: any = { userObj: this.globalVars.getBuyerObj() };

    if (that.actualLocationAddress) {
      params.address = that.actualLocationAddress;
    }

    if (this.globalVars.isVisibleSplitPane()) {
      
      params.modal = true;
      this.navigationDataService.setData("AddressListPage",params);

      const modal = await this.modalController.create(
        {
          component: AddressCreatePage,
          mode: 'ios',
          cssClass: 'create-addresss-modal'
        }
      );
  
      modal.present();

    } else {
      
      this.navigationDataService.setData("AddressCreatePage",params);
      this.router.navigate(['/address-create']);
    }
    this.navCtrl.pop();
  }

  async openCreateUser() {
    let that = this;

    let params: any = {
      orderObj: that.orderObj,
      origin: that.origin,
      actualLocationAddress: that.actualLocationAddress,
      callBack: (userObj) => {
        if (that.callBack) {
          that.callBack(userObj);
        }
        that.close();
      }
    };

    await this.openCreateUserPage(params);
  }

  private async openCreateUserPage(params: any) {

    if (this.globalVars.isVisibleSplitPane()) {

      params.modal = true;
      this.navigationDataService.setData("CreateUserPage", params);

      const modal = await this.modalController.create(
        {
          component: CreateUserPage,
          mode: 'ios',
          cssClass: 'create-user-modal'
        }
      );

      modal.present();
    } else {
      this.close();
      this.navigationDataService.setData("CreateUserPage", params);
      this.router.navigate(['/create-user']);
    }
  }

  async openEditUserPage(userObj, editUserCallBack) {

    let params: any = {
      orderObj: this.orderObj,
      userObj: userObj,
      origin: Origin.ENTRY_PAGE_FACEBOOK_LOGIN,
      actualLocationAddress: this.actualLocationAddress,
      callBack: (userObj) => {
        editUserCallBack(userObj);
      }
    };

    this.openCreateUserPage(params);
  }

  async openAddressListPage() {
    let params:any = { orderObj: this.orderObj, actualLocationAddress: this.actualLocationAddress };

    if (this.globalVars.isVisibleSplitPane()) {

      params.modal = true;
      this.navigationDataService.setData("AddressListPage",params);

      const modal = await this.modalController.create(
        {
          component: AddressListPage,
          componentProps: params,
          mode: 'ios',
          cssClass: 'create-user-modal'
        }
      );

      modal.present();

    } else {
      this.navigationDataService.setData("AddressListPage",params);
      this.router.navigate(['/address-list']);
    }

    this.navCtrl.back();
  }

  resetPassword() {
    if (this.globalVars.getBuyerObj()) {
      this.confirmToLogedUser();
    } else {
      this.confirmToNotLogedUser();
    }
  }

  confirmToLogedUser() {

    this.alertController.create({
      message: 'Um email será enviado para ' + this.globalVars.getBuyerObj().email + ' com as instruções para redefinição de senha. Deseja continuar?',
      buttons: [
        {
          text: 'Não',
          handler: () => { }
        },
        {
          text: 'Sim',
          handler: () => {
            this.userService.resetPasswordUser(this.globalVars.getBuyerObj().email, (type, objData) => {
              if (type == 'sucess') {
                this.alertInfo();
              }
            });
          }
        }
      ]
    }).then(res => {
      res.present();
    });

  }

  confirmToNotLogedUser() {
    this.alertController.create({
      message: 'Informe o email de sua conta para que possamos enviar as instruções para redefinição de senha.',
      inputs: [
        {
          name: 'email',
          placeholder: 'Email de sua conta'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Enviar',
          handler: data => {
            if (data.email) {
              let email = data.email.toLowerCase();

              this.userService.resetPasswordUser(email, (type, objData) => {
                if (type == 'sucess') {
                  this.alertInfo();
                }
              });
            }
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  alertMsg(msg: string) {
    this.alertController.create({
      message: msg,
      buttons: ['Ok']
    }).then(res => {
      res.present();
    });
  }

  alertInfo() {
    this.alertController.create({
      message: 'Processo de redefinição de senha iniciado com sucesso. Acesse seu email e siga as instruções.',
      buttons: ['Ok']
    }).then(res => {
      res.present();
    });
  }

  alertInfoErrorServer(msg: string) {

    this.alertController.create({
      message: msg,
      buttons: ['ENTENDI']
    }).then(res => {
      res.present();
    });
  }

  captchaResolved(response: string): void {
    let that = this;

    this.globalVars.setRecaptchaInfo(null);

    this.zone.run(() => {
      that.captchaPassed = true;
      that.globalVars.setRecaptchaInfo(response);
    });
  }

}
