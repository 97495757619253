import { Injectable } from '@angular/core';
import { GlobalVars } from '../util/global-vars';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FreightService {

  baseURL:string;
  authText:string;

  constructor(private http: HttpService, private globalVars: GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
   }

  getFreightByCityAndDistrict(city:string,location:string,successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      },
      params: { city: city,location: location}
    };
    
    this.http.get(this.baseURL+'/rs/crud/freights/search', config, successCallback, errorCallback);
  }

  getFreightCities(successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };
    
    this.http.get(this.baseURL+'/rs/crud/freights/freightCities', config, successCallback, errorCallback);
  }

  getFreightNeighborhoods(cityName:string,successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      },
      params: {city: cityName}
    };
    
    this.http.get(this.baseURL+'/rs/crud/freights/freightNeighborhoods', config, successCallback, errorCallback);
  }
}
