export class EventKey {
      
      //Evento de autenticacao valida
      public static LOGIN = "entry:autheticate";

      //Evento de autenticacao valida
      public static LOGIN_VENDEMAIS = "entry:autheticatevendemais";

      //Evento de saida do usuario;
      public static LOGOUT = "entry:logout";

      //Evento de saida do usuario;
      public static LOGOUT_VENDEMAIS = "entry:logoutvendemais";

      //Evento de abertura de tela de login pelo menu
      public static OPEN_ENTRY = "openentry";

      //Evento de abertura de tela de login pelo menu
      public static CLOSE_ENTRY = "closeentry";

      //Evento de abertura de tela de login pelo menu
      public static OPEN_CREATEUSER = "opencreateuser";

      //Evento de abertura de tela de login pelo menu
      public static CLOSE_CREATEUSER = "closecreateuser";

      //Evento de abertura de tela de login pelo menu
      public static OPEN_EDITUSER = "openedituser";

      //Evento de abertura de tela de login pelo menu
      public static OPEN_CREATEADDRESS = "opencreateaddress";

      //Evento de abertura de tela de login pelo menu
      public static CLOSE_CREATEADDRESS = "closecreateaddress";

      //Evento de abertura da Home;
      public static OPEN_HOME = "menu:home";

      //Evento de abertura da pagina do carrinho;
      public static OPEN_CART = "menu:cart";

      //Evento de abertura de autenticacao para edicao de dados do usuário
      public static OPEN_ENTRY_EDIT_USER = "menu:edituser";

      //Evento de abertura de page sobre
      public static OPEN_ABOUT = "menu:about";
      
      public static OPEN_BONUS_INFO = "menu:openbonusinfo";

      public static OPEN_BONUS = "openbonuspage";

      public static OPEN_CONTACTUS = "menu:opencontatcus";

      public static OPEN_PAYMENT_DELIVERY_INFO = "menu:openpaymentdelivery";

      public static CLOSE_SEARCH = "closesearch";

      public static OPEN_PRODUCT_PAGE = "openproductpage";

      public static OPEN_NOTIFICATION = "opennotification";

      public static FINISH_ORDER = "finishorder";
      
      public static GET_GEO_LOCATION = "getgeolocation";

      public static CART_REMOVED = "cart:removed";

      public static CART_UPDATE = "cart:update";

      public static CART_PRODUCT_ADD = "cart:product:add";

      public static CART_PRODUCT_UPDATE = "cart:product:update";

      public static CART_PRODUCT_REMOVE = "cart:product:remove";

      public static WISHLIST_PRODUCT_ADD = "wishlist:product:add";

      public static USER_CLIENT_STORAGE_CHANGED = "userclientstoragechanged";

      public static SETTINGS_CHANGED = "settingschange";

      public static USER_STORAGE_CHANGED = "userstoragechanged";

      public static CHANGE_GLOBAL_BUYER_OBJ = "changeglobalbuyerobj";

      public static CHANGE_GLOBAL_CLIENT_OBJ = "changeglobalclientobj";

      public static VISIBLE_SPLIT_PANEL = "visibleSplitPanel";
      
      public static HIDDEN_SPLIT_PANEL = "hiddenSplitPanel";

      public static MENU_CATEGORY = "menu:category";

      public static MENU_ORDER_LIST = "menu:orderlist";

      public static GET_ACTUAL_LOCATION_ADDRESS = "getActualLocationAddress";

      public static CLICK_OPEN_PAGE = "clickOpenPage";

      public static RELOAD_PWA_APP = "reloadPWAApp";

      public static CHANGE_CLIENT_APP = "changeClientApp";

      public static CHANGE_CLIENT_APP_BY_PRODUCT_PAGE = "changeClientAppByProductPage";

      public static SENDING_NAVIGATION_DATA = "SENDING_NAVIGATION_DATA";

      constructor() {}
}