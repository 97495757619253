import { Injectable } from '@angular/core';
import { GlobalVars } from '../util/global-vars';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  baseURL:string;
  authText:string;

  constructor(private http: HttpService, private globalVars: GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
   }

  save(address:any, idBuyer:number, callBack) {

    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers: {
        Authorization: 'Basic ' + btoa(this.authText),
        'Content-Type': 'application/json'
      }
    };
    
    this.http.post(this.baseURL+'/rs/crud/buyers/'+idBuyer+'/addresses', address, config, 
    (data)=>{
      callBack('sucess',data);
    }, 
    (error)=>{
      callBack('fail',error);
    });
  }
  
}
