import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  //TODO: Verificar pq depois de usar configuracao padrao PWA do Angular no projeto, essa linha teve de ser comentada devido a um erro quando rodando em producao.
  //enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
