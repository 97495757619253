import { LoadingController, Platform, ToastController } from "@ionic/angular";
import { StorageService } from "src/app/services/storage.service";

export class MasterPage {

  public loading: any;
  public modeView: string = 'grid';
  public prefeKey: string = 'preferenceapp';

  private maxWidthViewport:number = 430;
  private minWidthViewport:number = 250;

  constructor(public loadingCtrl: LoadingController, 
              public storage: StorageService, 
              public platform:Platform) {

  }

  init(){
    this.getPreferencesJson((jsonPreferences) => {
      this.getPrefViewMode((modeViewValue) => {
        this.modeView = modeViewValue;
      });
    });
  }

  async presentLoading(msg:string):Promise<HTMLIonLoadingElement> {
    let that = this;

    that.loading = await that.loadingCtrl.create({
      spinner: 'crescent',
      message: msg
    });

    that.loading.present();

    return that.loading;
  }

  dismissLoading(){
    if(this.loading){
      this.loadingCtrl.dismiss();
    }
  }

  public getPreferencesJson(callBack){

    this.storage.get(this.prefeKey).then((preferenceString) => {
      if(preferenceString == undefined || preferenceString == null || preferenceString == ''){
        preferenceString = '{"viewMode": "grid"}';
        this.storage.set(this.prefeKey, preferenceString);
      }
      callBack(JSON.parse(preferenceString));
    });

  }

  public getPrefViewMode(callBack){
    this.getPreferencesJson((jsonPreferences) =>{
      callBack(jsonPreferences.viewMode);
    });
  }

  public setPrefViewMode(viewMode: string){

    this.getPreferencesJson((jsonPreferences) => {
      if(jsonPreferences == undefined || jsonPreferences.viewMode == undefined){
        var jsonIni = '{"viewMode": "'+viewMode+'"}';
        this.storage.set(this.prefeKey, jsonIni);
      }else{
        jsonPreferences.viewMode = viewMode;
        this.storage.set(this.prefeKey, JSON.stringify(jsonPreferences));
      }
    });
  }

  public changeModeView(){

    if(this.modeView == "grid"){
      this.modeView = "list";
      this.setPrefViewMode("list");
    }else{
      this.modeView = "grid";
      this.setPrefViewMode("grid");
    }
  }

  public setModeView(modeViewValue:string){
    this.modeView = modeViewValue;
  }

  showBottomToast(toastCtrl: ToastController, msg: string) {
  
  let toast = toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'bottom'
    }).then((toastData) => {
      toastData.present();
    });
  }

  public isBigScreen():boolean{
    return this.platform.width() > this.maxWidthViewport;
  }

  public isNormalScreen():boolean{
    return this.platform.width() > this.minWidthViewport && this.platform.width() <= this.maxWidthViewport;
  }

  public isSmallScreen():boolean{
    return this.platform.width() <= this.minWidthViewport;
  }

}
