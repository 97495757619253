import { Injectable } from '@angular/core';
import { Events } from 'src/app/util/Events';
import { EventKey } from '../util/event-key';

@Injectable({
  providedIn: 'root'
})
export class NavigationDataService {

  private navigationData:Map<string,any> = new Map<string,any>();

  constructor(public events:Events) {}

  public setData(key:string, data:any){
    this.navigationData.set(key, data);
    this.events.publish(EventKey.SENDING_NAVIGATION_DATA, data);
  }

  public getData(key:string):any{
    let data = this.navigationData.get(key);
    return data;
  }
}
