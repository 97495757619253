import { Injectable } from '@angular/core';
import { GlobalVars } from '../util/global-vars';
import { HttpService } from './http.service';
import { OrderModel } from '../models/order-model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {

  SOURCE_TYPE_SITE: string = "Site";
  SOURCE_TYPE_APP: string = "App";

  private facebookPixelID;

  baseURL: string;
  authText: string;

  constructor(private router: Router, private http: HttpService, private globalVars: GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  public init() {
    if (this.isSetup()) {
      console.log('Facebook Pixel Iniciado!!!');
    }
  }

  public pageView(pageName: String, callBack: any) {

    if (this.isSetup()) {

      let endPoint = this.baseURL + '/rs/crud/facebookEvents/pageView';

      let jsonFBPageView = {
        pageName: pageName,
        buyer: this.globalVars.getBuyerObj(),
        sourceType: this.globalVars.isPwa() ? this.SOURCE_TYPE_SITE : this.SOURCE_TYPE_APP
      }

      this.postEvent(endPoint, jsonFBPageView, callBack, pageName);
    }
  }

  public addToWishlist(productId: string, pageName: String, callBack: any) {
    if (this.isSetup()) {

      let endPoint = this.baseURL + '/rs/crud/facebookEvents/addToWishlist';

      let jsonFB = {
        pageName: pageName,
        productId: productId,
        buyer: this.globalVars.getBuyerObj(),
        sourceType: this.globalVars.isPwa() ? this.SOURCE_TYPE_SITE : this.SOURCE_TYPE_APP
      }

      this.postEvent(endPoint, jsonFB, callBack, pageName);
    }
  }

  public addToCart(productId: string, pageName: String, callBack: any) {
    if (this.isSetup()) {

      let endPoint = this.baseURL + '/rs/crud/facebookEvents/addToCart';

      let jsonFB = {
        pageName: pageName,
        productId: productId,
        buyer: this.globalVars.getBuyerObj(),
        sourceType: this.globalVars.isPwa() ? this.SOURCE_TYPE_SITE : this.SOURCE_TYPE_APP
      }

      this.postEvent(endPoint, jsonFB, callBack, pageName);
    }
  }

  public initiateCheckout(order: OrderModel, pageName:string, callBack: any) {
    if (this.isSetup()) {

      let endPoint = this.baseURL + '/rs/crud/facebookEvents/initiateCheckout';

      let jsonFB = {
        pageName: pageName,
        orders: order,
        sourceType: this.globalVars.isPwa() ? this.SOURCE_TYPE_SITE : this.SOURCE_TYPE_APP,
      }

      this.postEvent(endPoint, jsonFB, callBack, pageName);
    }
  }

  public purchase(order: OrderModel, pageName:string, callBack: any) {
    if (this.isSetup()) {

      let endPoint = this.baseURL + '/rs/crud/facebookEvents/purchase';

      let jsonFB = {
        pageName: pageName,
        orders: order,
        sourceType: this.globalVars.isPwa() ? this.SOURCE_TYPE_SITE : this.SOURCE_TYPE_APP,
      }

      this.postEvent(endPoint, jsonFB, callBack, pageName);
    }
  }

  public completeRegistration(nomeRegistro) {
    if (this.isSetup()) {
      //fbq('track', 'CompleteRegistration',{content_name: nomeRegistro});
      console.log('Facebook Pixel - CompleteRegistration');
    }
  }

  private postEvent(endPoint: string, jsonFB:any, callBack: any, pageName: String) {

    this.authText = this.globalVars.getAuthText();
    let config = {
      headers: {
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.post(endPoint, jsonFB, config,
      (data) => {
        if (callBack)
          callBack('sucess', data);
        console.log('Track FB Event PageView for : ' + pageName);
      },
      (error) => {
        if (callBack)
          callBack('fail', error);
        console.log('FB Event - Ocorreu um problema [PageView : ' + pageName + ']');
      });
  }

  public isSetup(): boolean {
    if (this.globalVars.getSettingsObj()) {
      this.facebookPixelID = this.globalVars.getSettingsObj().facebookPixelID;
      return (this.facebookPixelID && this.facebookPixelID.trim().length > 0);
    } else {
      return false;
    }
  }

  private getContents(order: OrderModel): Array<any> {
    let contents = [];

    for (let item of order.itemOrders) {
      contents.push({
        id: item.product.id,
        quantity: item.amount
      });
    }

    return contents;
  }

}
