import { Injectable } from '@angular/core';
import { OneSignalPlayerModel } from 'src/app/models/one-signal-player-model';
import { GlobalVars } from 'src/app/util/global-vars';
import { ClientService } from './client.service';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class OneSignalPlayerService {

  private baseUrl: string;
  private authText;

  constructor(public http: HttpService,
    public storage: StorageService,
    public userService: UserService,
    public clientProvider: ClientService,
    public globalVars: GlobalVars) {

    this.baseUrl = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  saveOneSignalPlayerIdForBuyer(oneSignalPlayerId, callBack) {
    this.authText = this.globalVars.getAuthText();

    let that = this;
    let owner = this.globalVars.getClientObj();

    this.userService.getStoragedLocalUserObj((buyer) => {

      if (owner && buyer && oneSignalPlayerId) {

        let oneSignalPlayer = new OneSignalPlayerModel();
        oneSignalPlayer.buyer = buyer;
        oneSignalPlayer.client = owner;
        oneSignalPlayer.playerId = oneSignalPlayerId;

        that.saveOneSignalPlayer(oneSignalPlayer, (type, data) => {
          callBack(type, data);
        })
      }
    });
  }

  saveOneSignalPlayer(oneSignalPlayer: OneSignalPlayerModel, callBack) {
    this.authText = this.globalVars.getAuthText();
    
    if (oneSignalPlayer) {
      let config = {
        headers: {
          Authorization: 'Basic ' + btoa(this.authText),
          'Content-Type': 'application/json'
        }
      };

      this.http.post(this.baseUrl + '/rs/crud/oneSignalPlayers', oneSignalPlayer.getJsonObj(), config,
        (data) => {
          callBack('sucess', data);
        },
        (error) => {
          callBack('error', error);
        });
    }
  }

}
