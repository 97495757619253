import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Events } from 'src/app/util/Events';
import { GlobalVars } from 'src/app/util/global-vars';
import { HttpService } from './http.service';
//import { Geolocation } from '@ionic-native/geolocation/ngx';

declare var google;

@Injectable({
  providedIn: 'root'
})
export class UserLocationService {

  private lastCurrentAddress: any;

  constructor(public http: HttpService, 
    //public geolocation: Geolocation, 
    public platform: Platform,
    public globalVars: GlobalVars, 
    public events: Events) { }

  getCurrentAddress(callBack) {

    if (this.platform.is('android') || this.platform.is('ios')) {
      let that = this;
      let options = { timeout: 10000, enableHighAccuracy: true };
      /*
      this.geolocation.getCurrentPosition(options).then(
        (resp) => {

        let latlng = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
        var geocoder = new google.maps.Geocoder();

        geocoder.geocode({ 'latLng': latlng }, function (results, status) {

          let address: any = {
            id: null,
            name: "Localização Atual",
            zipCode: null,
            lineAddress: null,
            state: null,
            district: null,
            city: null,
            latitude: null,
            longitude: null,
            number: null, complement: null
          };

          if (status == google.maps.GeocoderStatus.OK) {

            if (results[0]) {

              let resultComponents = results[0].address_components;
              let zipCode = (that.getAddressComponent("postal_code", false, resultComponents)) ? that.getAddressComponent("postal_code", false, resultComponents).replace("-", "") : "";

              address.lineAddress = that.getAddressComponent("route", false, resultComponents);
              address.district = that.getAddressComponent("sublocality", false, resultComponents);
              address.city = that.getAddressComponent("locality", false, resultComponents);
              address.state = that.getAddressComponent("administrative_area_level_1", true, resultComponents);
              address.number = that.getAddressComponent("street_number", true, resultComponents);
              address.zipCode = zipCode;
              address.latitude = resp.coords.latitude;
              address.longitude = resp.coords.longitude;

              that.lastCurrentAddress = address;
              callBack(address);

              that.globalVars.setActualLocationAddress(address);
              that.events.publish(EventKey.GET_ACTUAL_LOCATION_ADDRESS, address);

            } else {
              callBack(null);
            }

          } else {

            callBack(null);
            console.error('Geocoder failed due to: ' + status);
          }
        });

      },
      (error)=>{
        console.error(error);
      }).catch(
        (err) => {
          console.error(err);
      });
      */
    }
  }

  getLastCurrentAddress(): any {
    return this.lastCurrentAddress;
  }

  //TODO: Ver outra forma de obter dados do CEP. Esse fornecedor pode passar a não fornecer mais essas informacoes.
  getAddressByCEP(cep: string) {
    return new Promise(resolve => {
      this.http.getPromise('https://viacep.com.br/ws/' + cep + '/json/',null)
        .then(res => {
          resolve(res);
        }).catch(err=> {
          console.error(err);
          resolve(null);
        });
    });
  }

  private getAddressComponent(type: string, shortName: boolean, resultComponents: any) {
    for (let addressComponent of resultComponents) {
      let types: Array<string> = addressComponent.types;
      if (types.indexOf(type) > -1) {
        if (shortName) {
          return addressComponent.short_name;
        } else {
          return addressComponent.long_name;
        }
      }
    }
  }
}
