import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { NavigationDataService } from 'src/app/services/navigation-data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import { EventKey } from 'src/app/util/event-key';
import { Events } from 'src/app/util/Events';
import { GlobalVars } from 'src/app/util/global-vars';
import { MasterPage } from 'src/app/util/master-page';
import { Origin } from 'src/app/util/origin';
import { Util } from 'src/app/util/util';
import { StorageService } from '../../services/storage.service';
import { AddressCreatePage } from '../address-create/address-create.page';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.page.html',
  styleUrls: ['./address-list.page.scss'],
})
export class AddressListPage extends MasterPage implements OnInit {

  listViewAddress: any[] = [];
  userObj: any;
  exisitAddress: boolean = false;
  notExisitAddress: boolean = false;

  withdrawInStore: boolean = false;
  receiveProductHome: boolean = false;
  onlinePayment: boolean = false;
  payOnDelivery: boolean = false;

  orderObj: any;
  origin: any;
  callBack: any;
  actualLocationAddress: any;

  isNotIosPlatform: boolean;
  isNoAddress: boolean;

  contentStyle = (this.globalVars.isVisibleSplitPane()) ? "ion-content-style ion-padding" : "ion-content-style";

  modal;

  constructor(
    public navCtrl: NavController,
    public userService: UserService,
    public loadingController: LoadingController,
    public storage: StorageService,
    public platform: Platform,
    public events: Events,
    public alertController: AlertController,
    public globalVars: GlobalVars,
    public modalController: ModalController,
    public activatedRouter: ActivatedRoute,
    public router: Router,
    public navigationDataService:NavigationDataService,
    public location: Location,
    public navigationService:NavigationService) {

    super(loadingController, storage, platform);
    
  }

  ngOnInit() {
    this.iniSetup();
  }

  private iniSetup() {
    let that = this;

    this.isNotIosPlatform = !this.platform.is('ios');
    let navigationData = this.navigationDataService.getData("AddressListPage");

    if(navigationData){
      that.orderObj = navigationData.orderObj;
      that.origin = navigationData.origin;
      that.callBack = navigationData.callBack;
      that.actualLocationAddress = navigationData.actualLocationAddress;
      that.modal = navigationData.modal;
    }

    this.events.subscribe(EventKey.GET_GEO_LOCATION, (addressLocation) => {
      if (!this.actualLocationAddress) {
        this.actualLocationAddress = addressLocation;
        this.loadAddressList();
      }
    });

    this.getClientSetup();
  }

  getClientSetup() {
    let that = this;

    let client = this.globalVars.getClientObj();
    that.withdrawInStore = client.withdrawInStore;
    //TODO: Essa informacao não esta vindo por isso esta sendo forcada aqui nesse momento. Corrigir isso.
    that.onlinePayment = client.onlinePayment;
    that.payOnDelivery = client.payOnDelivery;
    that.receiveProductHome = client.receiveProductHome;
  }

  ionViewDidEnter() {
    this.loadAddressList();
  }

  loadAddressList() {
    let that = this;

    this.userService.getStoragedLocalUser((userJson: any) => {
      if (userJson != null) {

        that.userObj = JSON.parse(userJson);
        that.listViewAddress = [];
        let zipCodeContain: number;

        for (let address of that.userObj.addresses) {

          if (that.actualLocationAddress) {
            if (address.zipCode == that.actualLocationAddress.zipCode) {
              zipCodeContain = address.zipCode;
              address.actualLocation = true;
            }
          }

          address.order = address.id;

          that.listViewAddress.push(address);
        }

        if (!zipCodeContain && that.actualLocationAddress) {
          that.actualLocationAddress.actualLocation = true;
          that.actualLocationAddress.order = 100000000;
          that.listViewAddress.push(that.actualLocationAddress);
        }


        that.setUpExistAddress();

        that.listViewAddress.sort(function (a, b) {

          if (a.order < b.order) return 1;
          if (a.order > b.order) return -1;

          return 0;
        });
      }

    });
  }

  disableAddress(address) {

    for (let i = 0; i < this.userObj.addresses.length; i++) {

      if (address.id == this.userObj.addresses[i].id) {
        this.userObj.addresses[i].disabled = true;
        break;
      }
    }

    for (let i = 0; i < this.listViewAddress.length; i++) {

      if (address.id == this.listViewAddress[i].id) {
        this.listViewAddress.splice(i, 1);
        break;
      }
    }

    this.userService.saveUser(this.userObj, (type, obj) => {

      if (type == 'sucess') {
        this.userService.storageLocalUser(obj);
      } else {
        console.error(obj);
      }

      this.setUpExistAddress();
    });
  }

  //TODO: Econtrar outra forma de saber se existe ou não endereços a serem exibidos
  setUpExistAddress() {
    let that = this;

    if (that.listViewAddress && that.listViewAddress.length > 0) {
      let allDisable = true;
      for (let address of that.listViewAddress) {
        if (!address.disabled) {
          allDisable = false;
          break;
        }
      }
      if (allDisable) {
        that.exisitAddress = false;
        that.notExisitAddress = true;
      } else {
        that.exisitAddress = true;
        that.notExisitAddress = false;
      }
    } else {
      that.exisitAddress = false;
      that.notExisitAddress = true;
    }

  }

  async openNextPage(address) {

    if (this.origin === Origin.MENU) {
      this.editAddress(address);
    } else if (this.origin === Origin.FREIGHT_PANEL) {
      if (this.callBack) {
        this.callBack(address);
      }
      

      if (!!this.modal) {
        this.modalController.dismiss();
      }else{
        this.navigationService.back('tabs/cart');
      }

    } else {

      if (!address.id) {
        this.openNewAddressPage(address);
      } else {

        this.orderObj.address = address;

        if (this.withdrawInStore && !this.receiveProductHome) {
          this.orderObj.wherePickupProduct = 'Loja';
        } else if (this.receiveProductHome) {
          this.orderObj.wherePickupProduct = 'Casa';
        }

        if (this.onlinePayment && this.payOnDelivery) {

          this.navigationDataService.setData("TypePaymentPage",{
            orderObj: this.orderObj
          });

          this.router.navigate(['/type-payment']);

        } else if (this.onlinePayment) {
          
          this.orderObj.typePayment = 'OnLine';

          Util.openGatewayPage(this.globalVars.getSettingsObj(), this.router, this.navigationDataService ,{ orderObj: this.orderObj } );

        } else {
          this.orderObj.typePayment = 'Entrega';

          this.navigationDataService.setData("PaymentMethodPage",{ orderObj: this.orderObj });

          this.router.navigate(['/payment-method']);
        }
      }
    }
  }

  async openNewAddressPage(address) {
    let that = this;

    let params: any = {
      userObj: that.userObj,
      origin: that.origin,
      callBack: (newAddress, loading) => {

        that.loadAddressList();

        if (loading) {
          loading.dismiss();
        }
      }
    };

    if (address) {
      params.address = address;
    } else if (that.actualLocationAddress) {
      params.address = that.actualLocationAddress;
    }

    this.openAddressCreatePage(params);
  }

  public removeAddress(address) {
    this.showConfirmRemove(() => {
      this.disableAddress(address);
    });
  }

  async editAddress(address) {
    let that = this;

    let params: any = {
      userObj: that.userObj,
      address: address,
      callBack: (editAddress, loading) => {

        if (that.globalVars.isVisibleSplitPane()) {
          that.loadAddressList();
        } else {
          that.orderObj.typePayment = 'Entrega';
          that.orderObj.address = editAddress;

          that.openPaymentMethod();
        }

        if (loading) {
          loading.dismiss();
        }
      }
    };

    this.openAddressCreatePage(params);
  }

  private openPaymentMethod() {

    this.navigationDataService.setData("PaymentMethodPage",{ orderObj: this.orderObj });

    this.router.navigate(['/payment-method']);
  }

  private async openAddressCreatePage(params) {
    if (this.globalVars.isVisibleSplitPane()) {

      params.modal = true;
      this.navigationDataService.setData("AddressCreatePage",params);

      const modal = await this.modalController.create(
        {
          component: AddressCreatePage,
          mode: 'ios',
          cssClass: 'create-addresss-modal'
        }
      );

      modal.present();
    } else {
      this.navigationDataService.setData("AddressCreatePage",params);
      this.router.navigate(['/address-create']);
    }
  }

  showConfirmRemove(yesCallBack) {

    this.alertController.create({
      message: 'Deseja realmente excluir essse endereço?',
      buttons: [
        {
          text: 'Não'
        },
        {
          text: 'Sim',
          handler: () => {
            yesCallBack();
          }
        }
      ]
    }).then((alert) => {
      alert.present();
    });

  }

  close() {
    if (!!this.modal) {
      this.modalController.dismiss();
    }
  }

  showEmptyList() {
    return !this.listViewAddress || this.listViewAddress.length == 0;
  }

  displayTitlePage(): boolean {
    return this.origin == Origin.CART_PAGE;
  }

}
