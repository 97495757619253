import { Injectable } from '@angular/core';
import { GlobalVars } from '../util/global-vars';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  baseURL:string;
  authText:string;

  constructor(private http: HttpService, private globalVars: GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
   }

  getAllOrdersByBuyerId(buyerId:number, successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };
    
    this.http.get(this.baseURL+'/rs/crud/orderss/allOrdersByBuyerId/'+buyerId, config, successCallback, errorCallback);

  }

  getOrdersListPage(params:any, successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();

    if(params){

        let config = {
          headers:{
            Authorization: 'Basic ' + btoa(this.authText)
          },
          params: params
        };
        
        this.http.get(this.baseURL+'/rs/crud/orderss', config, successCallback, errorCallback);
    } 
  }

  getLastOrderBy(buyerId:number, successCallback:any, errorCallback:any) {
    let that = this;

    this.authText = this.globalVars.getAuthText();
    let lastOrder;

    let params = {
      page: 1,
      pageSize: 1,
      orderBy: 'orderDate',
      direction: 'desc',
      buyer: buyerId,
      statusOrder: 'Concluido'
    };

    that.getOrdersListPage(params, (objData) => {
      if(objData && objData.itens && objData.itens.length > 0){
        lastOrder = objData.itens[0];
      }
      successCallback(lastOrder);
    }, (errorData) => {
      errorCallback();
      console.error(errorData);
    });
  }

  saveOrder(orderObj:any, callBack) {
    this.authText = this.globalVars.getAuthText();

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText),
        'X-CLIENT': this.globalVars.getXClient(),
        'X-RECAPTCHA-INFO': this.globalVars.getRecaptchaInfo()
      }
    };
    
    this.http.post(this.baseURL+'/rs/crud/orderss', orderObj, config, 
    (data)=>{
      callBack('sucess',data);
    }, 
    (error)=>{
      callBack('fail',error);
    });
  }

  budget(orderObj:any, callBack) {

    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };
    
    this.http.post(this.baseURL+'/rs/crud/orderss/budget', orderObj, config, 
    (data)=>{
      callBack('sucess',data);
    }, 
    (error)=>{
      callBack('fail',error);
    });
  }
}
