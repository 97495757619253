import { Injectable } from '@angular/core';

@Injectable()
export class OneSignalPlayerModel {
    
    id:number;
	playerId:string;
	buyer:any;
    client:any;

    getJsonObj():any{
        return {
            id:this.id,
            playerId:this.playerId,
            buyer:this.buyer,
            client:this.client
        }
    }

}