import { Injectable } from '@angular/core';
import { GlobalVars } from 'src/app/util/global-vars';
import { Util } from 'src/app/util/util';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

    baseURL:string;
    authText:string;

    listProductCategory: any;
    listProductByKeyword: any;
    product: any;

    constructor(public http: HttpService, public globalVars:GlobalVars) {
      this.baseURL = this.globalVars.getBaseURL();
      this.authText = this.globalVars.getAuthText();
    }

    loadProductByCategory(idCategory: string) {
      return new Promise(resolve => {

        this.authText = this.globalVars.getAuthText();

        let config = {
          headers:{
            Authorization: 'Basic ' + btoa(this.authText)
          }
        };

        this.http.getPromise(this.baseURL + '/rs/crud/products/allProductsByCategoryId/'+idCategory, config).then(
          (data) => {
            this.listProductCategory = data;
            resolve(this.listProductCategory);
        }).catch(
          (err) => {
            resolve(null);
            console.error(err);
        });
      });
    }

    searchProductListPageByOrder(pageIndex: number, pageSize: number, keyword: string, orderBy:string, order: string, 
        successCallback:any, errorCallback:any) {
        
        this.authText = this.globalVars.getAuthText();

        if(pageIndex && pageSize && pageIndex > 0 && pageSize > 0){

            let params:any  =  { 
                page: pageIndex, 
                pageSize: pageSize, 
                orderBy: orderBy, 
                direction: order, 
                onlyReferences: true, 
                disabled: false 
            };

            if(keyword && keyword.trim().length > 0){
                params.description = keyword;
            }

            let config = {
              headers:{
                Authorization: 'Basic ' + btoa(this.authText)
              },
              params: params
            };

            this.http.get(this.baseURL +'/rs/crud/products', config,successCallback,errorCallback);
        }
    }

    searchProductListPage(pageIndex: number, pageSize: number, keyword: string, successCallback:any, errorCallback:any) {
        this.authText = this.globalVars.getAuthText();

        if(pageIndex && pageSize && pageIndex > 0 && pageSize > 0){
            
          let config = {
            headers:{
              Authorization: 'Basic ' + btoa(this.authText)
            },
            params: { description: keyword,page: pageIndex, pageSize: pageSize, onlyReferences: true, disabled: false }
          };

          this.http.get(this.baseURL +'/rs/crud/products', config, successCallback, errorCallback);
        }
    }

    searchProductListLike(pageIndex: number, pageSize: number, keyword: string, successCallback:any, errorCallback:any) {
        this.authText = this.globalVars.getAuthText();

        if(pageIndex && pageSize && pageIndex > 0 && pageSize > 0){
            
          let config = {
            headers:{
              Authorization: 'Basic ' + btoa(this.authText)
            },
            params: { description: keyword,page: pageIndex, pageSize: pageSize, onlyReferences: true, disabled: false }
          };
          
          this.http.get(this.baseURL +'/rs/crud/products/filterAlike', config, successCallback, errorCallback);
        }
    }

    getProductListCategoryPage(pageIndex: number, 
        pageSize: number, categoryId: number, orderBy:string, order: string, successCallback:any, errorCallback:any) {
        this.authText = this.globalVars.getAuthText();

        if(pageIndex && pageSize && pageIndex > 0 && pageSize > 0 && categoryId && categoryId > 0){

          let config = {
            headers:{
              Authorization: 'Basic ' + btoa(this.authText)
            },
            params: {   page: pageIndex, 
              pageSize: pageSize, 
              productCategory: categoryId, 
              orderBy: orderBy, 
              direction: order, 
              onlyReferences: true, 
              disabled: false }
          };
          
          this.http.get(this.baseURL +'/rs/crud/products', config, successCallback, errorCallback);
        }
    }

    getProductListPage(params:any, successCallback:any, errorCallback:any) {
      this.authText = this.globalVars.getAuthText();

      if(params){

        let config = {
          headers:{
            Authorization: 'Basic ' + btoa(this.authText)
          },
          params: params
        };
        
        this.http.get(this.baseURL +'/rs/crud/products', config, successCallback, errorCallback);
      }
    }

    searchProductBy(keyword: string) {
      return new Promise(resolve => {

        this.authText = this.globalVars.getAuthText();

        let config = {
          headers:{
            Authorization: 'Basic ' + btoa(this.authText),
            params: { description: keyword, onlyReferences: true, disabled: false }
          }
        };

        this.http.getPromise(this.baseURL + '/rs/crud/products/filterAlike', config).then(
          (data) => {
            this.listProductByKeyword = data;
            resolve(this.listProductByKeyword);
        }).catch(
          (err) => {
            resolve(null);
            console.error(err);
        });
        
      });
    }

    getProductBy(id: string) {
      return new Promise(resolve => {

        this.authText = this.globalVars.getAuthText();

        let config = {
          headers:{
            Authorization: 'Basic ' + btoa(this.authText)
          }
        };

        this.http.getPromise(this.baseURL + '/rs/crud/products/' + id, config).then(
          (data) => {
            this.product = data;
            resolve(this.product);
        }).catch(
          (err) => {
            resolve(null);
            console.error(err);
        });
  
      });
    }

  /**
   * Retorna uma lista de json basico de produto (id, nome, disponibilidade)
   * 
  */

    getBasicProductsByListId(productIds:Array<number>, callBack:any) {
        this.authText = this.globalVars.getAuthText();

        let data = JSON.stringify(productIds);

        let config = {
          headers:{
            Authorization: 'Basic ' + btoa(this.authText),
            'Content-Type': 'application/json'
          }
        };
        
        this.http.post(this.baseURL+ '/rs/crud/products/getProductsByListId', data, config, 
        (data)=>{
          callBack('sucess',data);
        }, 
        (error)=>{
          callBack('fail',error);
        });
    }

    getProductsByListId(productIds:Array<number>, callBack:any) {
      this.authText = this.globalVars.getAuthText();

      let data = JSON.stringify(productIds);

      let config = {
        headers:{
          Authorization: 'Basic ' + btoa(this.authText),
          'Content-Type': 'application/json'
        }
      };
      
      this.http.post(this.baseURL+ '/rs/crud/products/productsByListId', data, config, 
      (data)=>{
        callBack('sucess',data);
      }, 
      (error)=>{
        callBack('fail',error);
      });
    }

    getActivePromotionalProductList(pageIndex: number, pageSize: number, orderBy:string, order: string, successCallback:any, errorCallback:any) {
        this.authText = this.globalVars.getAuthText();

        if(pageIndex && pageSize && pageIndex > 0 && pageSize > 0){

            let config = {
              headers:{
                Authorization: 'Basic ' + btoa(this.authText)
              },
              params: {   page: pageIndex, 
                pageSize: pageSize, 
                active:true, 
                orderBy: orderBy, 
                direction: order }
            };
            
            this.http.get(this.baseURL +'/rs/crud/promotionalProducts', config, successCallback, errorCallback);
        }
    }

    /**Retorna a list de promocoes forcando trazer apenas produtos referencias */
    getActivePromotionalOnlyReferencesProduct(successCallback:any, errorCallback:any) {
        this.authText = this.globalVars.getAuthText();

        let config = {
          headers:{
            Authorization: 'Basic ' + btoa(this.authText)
          }
        };
        
        this.http.get(this.baseURL +'/rs/crud/promotionalProducts/onlyReferencesProducts', config, successCallback, errorCallback);
    }
}
