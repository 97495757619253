import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, ModalController } from '@ionic/angular';
import { NavigationDataService } from 'src/app/services/navigation-data.service';
import { ProductService } from 'src/app/services/product.service';
import { EventKey } from 'src/app/util/event-key';
import { GlobalVars } from 'src/app/util/global-vars';
import { Events } from '../../util/events';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.page.html',
  styleUrls: ['./wishlist.page.scss'],
})
export class WishlistPage implements OnInit {

  arrayViewProdutos: any[] = [];
  productListMobileCol1: any[] = [];
  productListMobileCol2: any[] = [];
  productListDesktopCol1: any[] = [];
  productListDesktopCol2: any[] = [];
  productListDesktopCol3: any[] = [];
  productListDesktopCol4: any[] = [];
  emptyWishlist:boolean;
  showCartButton:boolean;

  constructor(
    public navCtrl: NavController,  
    public productService: ProductService, 
    public globalVars: GlobalVars, 
    public events: Events,
    public modalController: ModalController,
    public router: Router,
    public navigationDataService:NavigationDataService) {
      let that = this;

      this.events.subscribe(EventKey.WISHLIST_PRODUCT_ADD, () => {
        that.loadProductListByBuyerWishlist();
      });
  }

  ngOnInit() {
    this.loadProductListByBuyerWishlist();
  }

  ionViewDidEnter() {
    if(this.globalVars.getClientObj()){
      this.showCartButton = this.globalVars.getClientObj().showCartButton;
    }
  }

  loadProductListByBuyerWishlist(){

    this.clearView();

    let buyerObj = this.globalVars.getBuyerObj();

    if(buyerObj && buyerObj.buyerWishList && buyerObj.buyerWishList.length > 0){
      
      let productIdList = new Array<number>();

      for(let product of buyerObj.buyerWishList){
        productIdList.push(product.id);
      }
      
      this.productService.getProductsByListId(productIdList,(type,jsonObj)=>{
        if(type=='sucess'){
          
          for (let product of jsonObj) {
            this.arrayViewProdutos.push(product);
            this.pushProductInListForMobileView(product);
            this.pushProductInListForDesktopView(product);
          }

          if(this.arrayViewProdutos.length == 0){
            this.emptyWishlist = true;
          }else{
            this.emptyWishlist = false;
          }
        } 
      });

    }else{
      this.emptyWishlist = true;
    }
  }

  clearView() {
    this.arrayViewProdutos.splice(0,this.arrayViewProdutos.length);
    this.productListMobileCol1.splice(0, this.productListMobileCol1.length);
    this.productListMobileCol2.splice(0, this.productListMobileCol2.length);
    this.productListDesktopCol1.splice(0, this.productListDesktopCol1.length);
    this.productListDesktopCol2.splice(0, this.productListDesktopCol2.length);
    this.productListDesktopCol3.splice(0, this.productListDesktopCol3.length);
    this.productListDesktopCol4.splice(0, this.productListDesktopCol4.length);
  }

  keepBuying(){
    this.router.navigateByUrl('').then(ok => {
      this.events.publish(EventKey.OPEN_HOME);
    });
  }

  openProduct(product: any) {
    
    let that = this;

    return function openProductPage(){

      that.navigationDataService.setData("ProductPage",{
        product: product,
        pid: product.id
      });

      that.router.navigate(['/product']);
    };
  }

  close(){
    if(this.globalVars.isVisibleSplitPane()){
      this.modalController.dismiss();
    }else{
      this.navCtrl.back();
    }
  }

  pushProductInListForMobileView(product){
    if(this.productListMobileCol1.length > this.productListMobileCol2.length){
      this.productListMobileCol2.push(product);
    }else{
      this.productListMobileCol1.push(product);
    }
  }

  pushProductInListForDesktopView(product){
    if(this.productListDesktopCol1.length > this.productListDesktopCol2.length){
      this.productListDesktopCol2.push(product);
    }else if(this.productListDesktopCol2.length > this.productListDesktopCol3.length){
      this.productListDesktopCol3.push(product);
    }else if(this.productListDesktopCol3.length > this.productListDesktopCol4.length){
      this.productListDesktopCol4.push(product);
    }else{
      this.productListDesktopCol1.push(product);
    }
  }

}
