import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { BusinessHoursModel } from 'src/app/models/business-hours-model';
import { BusinessHoursService } from 'src/app/services/business-hours.service';
import { MethodPaymentService } from 'src/app/services/method-payment.service';
import { UsageStatisticsService } from 'src/app/services/usage-statistics.service';
import { GlobalVars } from 'src/app/util/global-vars';
import { ClientService } from '../../services/client.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-delivery-payment-info',
  templateUrl: './delivery-payment-info.page.html',
  styleUrls: ['./delivery-payment-info.page.scss'],
})
export class DeliveryPaymentInfoPage implements OnInit {

  limitValueFreeFreight:number;
  defaultDeliveryTime:number;
  urlImg: string;
  minimumPurchaseValue:number;
  businessHours:Array<BusinessHoursModel>;

  payOnDelivery:boolean;
  cashPayment:boolean;
  debitPayment:boolean;
  creditCardPayment:boolean;
  voucherPayment:boolean;
  bankSlipPayment:boolean;

  receiveProductHome:boolean;
  withdrawInStore:boolean;
  deliveryByCarrier:boolean;

  displayMsgLoading:boolean;
  displayPageContent: boolean;

  constructor(public navCtrl: NavController, 
    public usageStatisticsProvider:UsageStatisticsService,
    public storage: StorageService,
    public clientProvider:ClientService,
    public businessProvider:BusinessHoursService,
    public methodPaymentProvider:MethodPaymentService,
    public globalVars:GlobalVars,
    public modalController:ModalController) {

    usageStatisticsProvider.sendUsageStatistics("DeliveryPaymentInfoPage");

    this.urlImg = "assets/img/moto-entrega-1h.jpeg";
    console.log("Construtor....");
  }

  ngOnInit() {
  }

  ionViewDidEnter(){
    let that = this;
    
    this.globalVars.setPageLoaded('DeliveryPaymentInfoPage',true);
    this.displayMsgLoading = true;
    this.displayPageContent = false;

    this.getClientSetup();
    this.getBusinessHours();
    
    this.getListMethodPayment(function(){
      that.displayMsgLoading = false;
      that.displayPageContent = true;
    });
  }

  getClientSetup() {
    let that = this;
    let owner = this.globalVars.getClientObj();
    
    that.payOnDelivery = owner.payOnDelivery;
    that.limitValueFreeFreight = owner.limitValueFreeFreight;
    that.defaultDeliveryTime = owner.defaultDeliveryTime;
    that.minimumPurchaseValue = owner.minimumPurchaseValue;
    that.receiveProductHome = owner.receiveProductHome;
    that.withdrawInStore = owner.withdrawInStore;
    that.deliveryByCarrier = owner.deliveryByCarrier;

    that.setupImgDelivery();
  }

  getListMethodPayment(callBack) {
    let that = this;
    that.methodPaymentProvider.getListMethosPayment(
    (listPayment) => {
      
      for(let paymentItem of listPayment){
        if(paymentItem.type == 'CASH'){
          that.cashPayment = true;
        }else if(paymentItem.type == 'DEBIT'){
          that.debitPayment = true;
        }else if(paymentItem.type == 'CARD'){
          that.creditCardPayment = true;
        }else if(paymentItem.type == 'VOUCHER'){
          that.voucherPayment = true;
        }else if(paymentItem.type == 'BANK_SLIP'){
          that.bankSlipPayment = true;
        }
      }
      callBack();
    }, (objError) => {
      callBack();
      console.error(objError);
    });
  }

  getBusinessHours(){
    this.businessProvider.getBusinessHours((sucessData)=>{
      if(sucessData && sucessData.itens){
        this.businessHours = sucessData.itens;
      }
    },(errorData)=>{
      console.error(errorData);
    });
  }

  setupImgDelivery(){
    if(this.defaultDeliveryTime == 2){
      this.urlImg = "assets/img/moto-entrega-2h.jpeg";
    }else  if(this.defaultDeliveryTime == 3){
      this.urlImg = "assets/img/moto-entrega-3h.jpeg";
    }else  if(this.defaultDeliveryTime == 4){
      this.urlImg = "assets/img/moto-entrega-4h.jpeg";
    }
  }

  getTranslatedNameDay(day:string):string{
    switch(day){
      case "MONDAY":{
        return "Segunda";
      }
      case "TUESDAY":{
        return "Terça";
      }
      case "WEDNESDAY":{
        return "Quarta";
      }
      case "THURSDAY":{
        return "Quinta";
      }
      case "FRIDAY":{
        return "Sexta";
      }
      case "SATURDAY":{
        return "Sábado";
      }
      case "SUNDAY":{
        return "Domingo";
      }
      default: { 
        console.log("Dia não encontrado."); 
        return day;              
     } 
    }
  }

  getMinimumPurchaseValueString(){
    return String(this.minimumPurchaseValue);
  }

  close(){
    if(this.globalVars.isVisibleSplitPane()){
      this.modalController.dismiss();
    }else{
      this.navCtrl.back();
    }
  }

}
