import { Injectable } from '@angular/core';
import { GlobalVars } from 'src/app/util/global-vars';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  
  baseURL;
  authText;

  constructor(public http: HttpService,public globalVars:GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  getSettings(successCallback:any, errorCallback:any){
    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.get(this.baseURL +'/rs/crud/settingss/one', config, successCallback, errorCallback);
  }
}
