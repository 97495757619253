import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, NavController, NavParams, Platform, ToastController, ModalController } from '@ionic/angular';
import { AddressService } from 'src/app/services/address.service';
import { FreightService } from 'src/app/services/freight.service';
import { NavigationDataService } from 'src/app/services/navigation-data.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserLocationService } from 'src/app/services/user-location.service';
import { UserService } from 'src/app/services/user.service';
import { EventKey } from 'src/app/util/event-key';
import { Events } from 'src/app/util/Events';
import { GlobalVars } from 'src/app/util/global-vars';
import { MasterPage } from 'src/app/util/master-page';
import { Util } from 'src/app/util/util';

@Component({
  selector: 'app-address-create',
  templateUrl: './address-create.page.html',
  styleUrls: ['./address-create.page.scss'],
})
export class AddressCreatePage extends MasterPage implements OnInit{

  public createForm: FormGroup;
  public masks: any;
  public userObj: any;

  public lineAddress: string;
  public district: string;
  public city: string;
  public state: string;

  public zipCode: string;
  public nome: string;
  public number: string;
  public complement: string;
  public referencePoint: string;

  public addressLocation;
  public addressEdit;

  //TODO: refatorar toda a logica de validacao de CEP que esta atualmente muito pulverizado no codigo.
  public isValidCEP: boolean = false;
  public isValidAddress: boolean = false;
  public msgInvalidCep = "O CEP informado não é válido.";

  public disableInput: boolean = false;
  public showInputCEP: boolean = true;
  public loadingSearchCEP:any;
  public address:any;
  public modal:boolean;
  public callBack;
  public searchCeptimeout;

  public listFreightCities: Array<any> = new Array<any>();

  constructor(public navCtrl: NavController,
    public formBuilder: FormBuilder,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public storage: StorageService,
    public userLocationService: UserLocationService,
    public userService: UserService,
    public addressService:AddressService,
    public platform: Platform,
    public alertController: AlertController,
    public events: Events,
    public globalVars: GlobalVars,
    public freightProvider: FreightService,
    public modalController: ModalController,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public navigationDataService:NavigationDataService,
    public location:Location) {

    super(loadingController, storage, platform);

    this.createForm = formBuilder.group({
      nome: new FormControl('', Validators.compose([Validators.required])),
      zipCode: new FormControl(''),
      lineAddress: new FormControl('', Validators.compose([Validators.required])),
      number: new FormControl('', Validators.compose([Validators.required])),
      complement: new FormControl(''),
      referencePoint: new FormControl(''),
      district: new FormControl('', Validators.compose([Validators.required])),
      city: new FormControl('', Validators.compose([Validators.required])),
      state: new FormControl('', Validators.compose([Validators.required]))
    });

    this.masks = {
      cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
    };

    events.subscribe(EventKey.GET_GEO_LOCATION, (addressLocation) => {
      if (!this.addressLocation) {
        this.addressLocation = addressLocation;
        this.setAddressLocation(addressLocation);
        this.isValidAddress = this.addressValidate(this.addressLocation);
      }
    });
  }

  ngOnInit() {
    let navigationData = this.navigationDataService.getData("AddressCreatePage");

    if(navigationData){
      this.userObj = navigationData.userObj;
      this.callBack = navigationData.callBack;
      this.address = navigationData.address;
      this.modal = navigationData.modal;
    }
  }

  ionViewDidEnter() {

    if (this.address && this.address.id) {
      this.addressEdit = this.address;

      this.setAddressEdit(this.addressEdit);
      this.isValidAddress = this.addressValidate(this.addressEdit);

      if (this.addressEdit.zipCode) {
        this.isValidCEP = true;
      }
    } else {
      this.addressLocation = this.address;

      if (this.addressLocation) {
        if (this.addressValidate(this.addressLocation)) {
          this.setAddressLocation(this.addressLocation);
        }
        if (this.addressLocation.zipCode) {
          this.updateAddressByCEP(this.addressLocation.zipCode);
        }
      }
    }

    this.getFreightCities();
  }

  getFreightCities() {
    let that = this;
    this.freightProvider.getFreightCities((sucessData) => {
      that.listFreightCities = sucessData;
    }, (errorData) => {
      console.error(errorData);
    });
  }

  private addressValidate(address): boolean {
    if (!address || (address && (!address.district || !address.city))) {
      return false;
    } else {
      return true;
    }
  }

  ionViewWillEnter() { }
  ionViewWillLeave() { }
  ionViewDidLeave() {
    this.events.publish(EventKey.CLOSE_CREATEADDRESS);
  }

  saveAction() {

    if (this.formIsValid()) {

      let cep = this.createForm.controls['zipCode'].value;

      if (this.isRequiredZipCode()) {

        if (cep && this.isValidCEP && String(cep).trim().length >= 8) {
          this.saveAddress();
        } else {
          this.showMsgInvalidCEP();
        }

      } else {

        if (cep && String(cep).trim().length > 0 && !this.isValidCEP) {
          this.showMsgInvalidCEP();
        } else {
          this.saveAddress();
        }
      }

    } else {
      Util.showMenssage(this.alertController, "Preencha todos os campos obrigatórios (*)!!");
    }
  }

  private async saveAddress() {
    let that = this;

    let loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: "Salvando Endereço."
    });

    loading.present();

    //this.setAddressToEditing();

    this.addressService.save(this.getNewAddress(),this.userObj.id,
    (type,obj)=>{
      if(type == 'sucess'){
        that.userObj.addresses.push(obj);
        that.orderAddressUser(that.userObj);
        that.userService.storageLocalUser(that.userObj);
        that.backNavigation(obj,loading);
      }else{
        that.backNavigation(null,loading);
        console.error(obj);
      }
    });

    /*
    this.userService.saveUser(this.userObj, (type, obj) => {
      if (type == 'sucess') {
        that.userService.storageLocalUser(obj);
        that.orderAddressUser(obj);
        that.backNavigation(obj.addresses[0],loading);
      } else {
        that.backNavigation(null,loading);
        console.error(obj);
      }
    });
    */
  }

  private backNavigation(address, loading){
    
    if(this.modal){
      this.modalController.dismiss();
    }else{
      this.location.back();
    }
    
    if(this.callBack){
      this.callBack(address, loading)
    }
  }

  private showMsgInvalidCEP() {
    this.msgInvalidCep = "O CEP informado não é válido.";
    Util.showMenssage(this.alertController, this.msgInvalidCep);
  }

  /**
   * Valida a necessidade de informar o CEP. Se a cidade informada no formulario 
   * não pertecer ao conjunto de cidades informadas como entrega local então não 
   * precisa informar um CEP.
   */
  private isRequiredZipCode(): boolean {
    let zipCode: string = this.createForm.controls['zipCode'].value;
    let city: string = this.createForm.controls['city'].value;

    if (!zipCode && city) {
      for (let cityName of this.listFreightCities) {
        if (cityName.name.trim().toLowerCase() == city.trim().toLowerCase()) {
          return false;
        }
      }
    }

    return true;
  }

  private setAddressToEditing() {
    if (this.addressEdit) {
      for (let i = 0; i < this.userObj.addresses.length; i++) {
        if (this.addressEdit.id == this.userObj.addresses[i].id) {
          this.fillAddressEdit(this.userObj.addresses[i]);
          break;
        }
      }
    } else {
      this.userObj.addresses.push(this.getNewAddress());
    }
  }

  private orderAddressUser(objUser) {
    objUser.addresses.sort(function (a, b) {

      if (a.id < b.id) return 1;
      if (a.id > b.id) return -1;

      return 0;
    });
  }

  private fillAddressEdit(addressEdit) {
    addressEdit.name = this.createForm.controls['nome'].value;
    addressEdit.lineAddress = this.createForm.controls['lineAddress'].value;
    addressEdit.number = this.createForm.controls['number'].value;
    addressEdit.complement = this.createForm.controls['complement'].value;
    addressEdit.referencePoint = this.createForm.controls['referencePoint'].value;
    addressEdit.district = this.createForm.controls['district'].value;
    addressEdit.city = this.createForm.controls['city'].value;
    addressEdit.state = this.createForm.controls['state'].value;

    if(this.createForm.controls['zipCode'].value){
      addressEdit.zipCode = this.createForm.controls['zipCode'].value.replace(/\D+/g, '');
    }else{
      this.showInputCEP = false;
    }
  }

  getNewAddress() {

    let latitude;
    let longitude;
    let zipCode = this.createForm.controls['zipCode'].value;

    if (this.addressLocation && this.addressLocation.latitude && this.addressLocation.longitude) {
      latitude = this.addressLocation.latitude;
      longitude = this.addressLocation.longitude;
    }

    let addressObj = {
      name: this.createForm.controls['nome'].value,
      zipCode: (zipCode) ? zipCode.trim() : null,
      lineAddress: this.createForm.controls['lineAddress'].value,
      number: this.createForm.controls['number'].value,
      complement: this.createForm.controls['complement'].value,
      referencePoint: this.createForm.controls['referencePoint'].value,
      district: this.createForm.controls['district'].value,
      city: this.createForm.controls['city'].value,
      state: this.createForm.controls['state'].value,
      latitude: latitude,
      longitude: longitude
    }

    return addressObj;
  }

  searchCep(ev: any) {
    let that = this;
    this.disableInput = false;
    let keyword: string = ev.target.value.replace(/\D+/g, '').trim();

    if (this.searchCeptimeout) {
      clearTimeout(this.searchCeptimeout);
    }

    if (keyword && keyword != '' && keyword.length >= 8) {

      that.searchCeptimeout = setTimeout(function () {
        that.updateAddressByCEP(keyword);
      }, 1500);
    }

  }

  private async updateAddressByCEP(cep: string) {
    let that = this;

    this.loading = await that.loadingCtrl.create({
      spinner: 'crescent',
      message: "Buscando CEP"
    });

    this.loading.present();

    //TODO: Ver uma forma de setar um timeout para essa requisição
    that.userLocationService.getAddressByCEP(cep).then(data => {

      that.msgInvalidCep = "O CEP parece não ser válido. Não foi possível encontrá-lo na nossa base de dados.";
      
      if (data && data['erro']) { 
        that.isValidCEP = false;
      } else if (!data) {
        that.isValidCEP = false;
      } else {
        that.isValidCEP = true;
        that.setAddressCEP(data);
      }

      that.loading.dismiss();

      if (!that.isValidCEP) {
        that.clear();
        Util.showMenssage(that.alertController, that.msgInvalidCep);
      }
    });
  }

  setAddressCEP(address: any) {
    this.lineAddress = address['logradouro'];
    this.district = address['bairro'];
    this.city = address['localidade'];
    this.state = address['uf'];
    this.disableInput = true;
  }

  setAddressLocation(address: any) {
    if (address) {
      this.zipCode = address.zipCode;
      this.lineAddress = address.lineAddress;
      this.district = address.district;
      this.city = address.city;
      this.state = address.state;
      this.disableInput = true;

      if(!this.zipCode){
        this.showInputCEP = false;
      }
    }
  }

  setAddressEdit(address: any) {

    if (address) {
      this.nome = address.name;
      this.zipCode = address.zipCode;
      this.lineAddress = address.lineAddress;
      this.number = address.number;
      this.complement = address.complement;
      this.referencePoint = address.referencePoint;
      this.district = address.district,
      this.city = address.city;
      this.state = address.state;
      this.disableInput = true;

      if(!this.zipCode){
        this.showInputCEP = false;
      }
    }
  }

  clear() {
    this.lineAddress = null;
    this.district = null;
    this.city = null;
    this.state = null;
    this.complement = null;
    this.referencePoint = null;
    this.number = null;
  }

  formIsValid() {
    return this.createForm.valid;
  }

  close() {
    if(this.globalVars.isVisibleSplitPane()){
      this.modalController.dismiss();
    }else{
      //this.navCtrl.back();
      this.location.back();
    }
  }

}
