import { Injectable } from '@angular/core';
import { GlobalVars } from '../util/global-vars';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessHoursService {

  baseURL:string;
  authText:string;

  constructor(private http: HttpService, private globalVars: GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
   }

  getBusinessHours(successCallback:any, errorCallback:any){

    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };
    
    this.http.get(this.baseURL+'/rs/crud/businessHourss', config, successCallback, errorCallback);
  }

}
