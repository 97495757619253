import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage;

  constructor(private storage: Storage) {
  }

  init(callBack) {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    console.log("Iniciando Storage!!!");
    this.storage.create().then((stg)=>{
      this._storage = stg;
      console.log("Storage Iniciado.");
      callBack(stg);
    });
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any):Promise<any> {
    return this._storage?.set(key, value);
  }

  public get(key: string):Promise<any>{
    console.log("Get do Storage Chamado!!!");
    return this._storage.get(key);
  }

  public remove(key: string):Promise<any>{
    return this._storage.remove(key);
  }
}
