import { Injectable } from '@angular/core';
import { GlobalVars } from '../util/global-vars';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MessageTypeService {

  baseURL;
  authText;

  constructor(public http: HttpService,public globalVars:GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  getListMessageType(successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };
    
    this.http.get(this.baseURL +'/rs/crud/messageTypes/all', config, successCallback, errorCallback);
  }
}
