import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonInput, NavController, NavParams } from '@ionic/angular';
import { NavigationDataService } from 'src/app/services/navigation-data.service';
import { GlobalVars } from 'src/app/util/global-vars';

@Component({
  selector: 'app-html-content',
  templateUrl: './html-content.page.html',
  styleUrls: ['./html-content.page.scss'],
})
export class HtmlContentPage implements OnInit {

  @Input('contentType') contentType: any;
  @Input('isModal') isModal: any;

  ABOUT_COMPANY = "ABOUT_COMPANY";
  DELIVERY_PAYMENT = "DELIVERY_PAYMENT";
  TERMS_CONDITIONS = "TERMS_CONDITIONS";
  PRIVACY_POLICIES = "PRIVACY_POLICIES";
  EXCHANGE_RETURNS_POLICIES = "EXCHANGE_RETURNS_POLICIES";

  showAboutCompany:boolean = false;
  showTermsCondition:boolean = false;
  showPrivacyPolices:boolean = false;

  title:string;

  constructor(public navCtrl: NavController,
    public globalVars: GlobalVars,
    public activateRoute: ActivatedRoute,
    public router: Router,
    public navigationDataService:NavigationDataService) {
  }

  ngOnInit() {

    let navigationData = this.navigationDataService.getData("HtmlContentPage");

    if(navigationData){
      this.contentType = navigationData.contentType;
      this.isModal = navigationData.isModal;
    }

    if(this.contentType){

      if(this.contentType == this.ABOUT_COMPANY){
        this.title = "Sobre a Empresa";
        this.showAboutCompany = true;
      }else if(this.contentType == this.TERMS_CONDITIONS){
        this.title = "Termos e Condições";
        this.showTermsCondition = true;
      }else if(this.contentType == this.PRIVACY_POLICIES){
        this.title = "Política de Privacidade";
        this.showPrivacyPolices = true;
      }else if(this.contentType == this.DELIVERY_PAYMENT){
        this.title = "Política de Privacidade";
        this.showPrivacyPolices = true;
      }
    }

    this.globalVars.setPageLoaded('HtmlContentPage',true);
  }

  showAbout():boolean{
    return (this.contentType == this.ABOUT_COMPANY 
      && this.globalVars.getSettingsObj() 
      && this.globalVars.getSettingsObj().about);
  }

  showTermsAndConditions():boolean{
    return (this.contentType == this.TERMS_CONDITIONS 
    &&this.globalVars.getSettingsObj() 
    && this.globalVars.getSettingsObj().termsAndConditions);
  }

  showPrivacyAndPolicies():boolean{
    return (this.contentType == this.PRIVACY_POLICIES 
    && this.globalVars.getSettingsObj() 
    && this.globalVars.getSettingsObj().privacyAndPolicies);
  }

  close(){
    this.navCtrl.pop();
  }

}
