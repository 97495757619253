import { Injectable } from '@angular/core';
import { GlobalVars } from '../util/global-vars';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  baseURL;
  authText;

  constructor(public http: HttpService,public globalVars:GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  saveMessage(msgObj:any, callBack) {
      this.authText = this.globalVars.getAuthText();

      let config = {
        headers:{
          Authorization: 'Basic ' + btoa(this.authText),
        }
      };
      
      this.http.post(this.baseURL+'/rs/crud/messages', msgObj, config, 
      (data)=>{
        callBack('sucess',data);
      }, 
      (error)=>{
        callBack('fail',error);
      });
  }
}
