import { Injectable } from '@angular/core';
import { GlobalVars } from 'src/app/util/global-vars';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MethodPaymentService {
  baseURL;
  authText;

  constructor(public http: HttpService, public globalVars: GlobalVars) {
    this.baseURL = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  getListMethosPayment(successCallback: any, errorCallback: any) {
    this.authText = this.globalVars.getAuthText();
    
    let params: any = {
      page: 1, 
      pageSize: 1000,
      disabled: false
    };

    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      },
      params: params
    };
    
    this.http.get(this.baseURL + '/rs/crud/wayPayments', config, (page)=>{
      if(page){
        successCallback(page.itens);
      }
    }, errorCallback);
    
  }
}
