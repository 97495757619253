import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { OneSignalPlayerService } from 'src/app/services/one-signal-player.service';
import { OrderService } from 'src/app/services/order.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { EventKey } from 'src/app/util/event-key';
import { Events } from 'src/app/util/Events';
import { GlobalVars } from 'src/app/util/global-vars';
import { MasterPage } from 'src/app/util/master-page';
import { Origin } from 'src/app/util/origin';
import { Util } from 'src/app/util/util';
import * as moment from 'moment-timezone';
import 'moment/locale/pt-br';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationDataService } from 'src/app/services/navigation-data.service';
import { AddressCreatePage } from '../address-create/address-create.page';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.page.html',
  styleUrls: ['./create-user.page.scss'],
})
export class CreateUserPage extends MasterPage implements OnInit {

  public createForm: FormGroup;
  public util: Util;
  public masks: any;
  public orderObj: any;
  public userObj: any;
  public origin: string;

  public nome: string;
  public sexo: string;
  public cpf: number;
  public email: string;
  public telefone: number;
  public celular: number;
  public dataNascimento: string;

  public displayMsgSucessFinish: boolean = false;
  public displayWaitCreateUser: boolean = false;
  public displayForm: boolean = true;

  public displayPassField: boolean = false;
  public completeRegistration: boolean = false;
  public showUpdatePasswordButton = false;
  public showDeleteButton: boolean = false;

  private oneSignalPlayerId: string;

  public title: string = "Crie sua conta.";
  private msgDeleteAccount = "Ao excluir sua conta de usuário, todos os seus dados pessoais assim como seu histórico "+
                             "de compras e cashback acumulado serão excluidos definitivamente.<br>"+
                             "Deseja realmente excluir sua conta de usuário ?";

  public actualLocationAddress: any;

  public callBack: any;

  titleContent;
  receiveInformation;

  captchaPassed:boolean;
  recaptchaPublicKey:string;

  constructor(
    public navCtrl: NavController,
    public formBuilder: FormBuilder,
    public toastCtrl: ToastController,
    public modalController: ModalController,
    public ldgController: LoadingController,
    public storage: StorageService,
    public userService: UserService,
    public platform: Platform,
    public alertController: AlertController,
    public events: Events,
    public oneSiginalPlayerProvider: OneSignalPlayerService,
    public globalVars: GlobalVars,
    public orderService:OrderService,
    public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public navigationDataService:NavigationDataService) {

    super(ldgController, storage, platform);

    this.iniSetup();
  }

  private iniSetup() {

    let that = this;

    this.events.subscribe(EventKey.GET_GEO_LOCATION, (addressLocation) => {
      if (!this.actualLocationAddress) {
        this.actualLocationAddress = addressLocation;
      }
    });

    this.createForm = this.formBuilder.group({
      cpf: new FormControl('', Validators.compose([
        Validators.minLength(11),
        Validators.maxLength(14),
        Validators.required
      ])),
      sexo: new FormControl(),
      //TODO: Nome não esta aceitando acento
      nome: new FormControl('', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern('^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$'),
        Validators.required
      ])),
      //TODO: Só aceitar letras minusculas
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      telefone: new FormControl('', Validators.compose([
        Validators.minLength(10),
        Validators.maxLength(15)
      ])),
      celular: new FormControl('', Validators.compose([
        Validators.minLength(10),
        Validators.maxLength(15),
        Validators.required
      ])),
      dateBirth: new FormControl('',Validators.compose([Validators.required])),
      senha: new FormControl('', Validators.compose([
        Validators.minLength(4)
      ])),
      confirmarsenha: new FormControl('', Validators.compose([
        Validators.minLength(4)
      ]))
    });

    if (this.globalVars.useRecaptcha()) {
      this.recaptchaPublicKey = this.globalVars.getSettingsObj().recaptchaPublicKey;
    }
  }

  ngOnInit() {
    let navigationData = this.navigationDataService.getData("CreateUserPage");

    if(navigationData){
      this.orderObj = navigationData.orderObj;
      this.origin = navigationData.origin;
      this.userObj = navigationData.userObj;
      this.callBack = navigationData.callBack;
      this.actualLocationAddress = navigationData.actualLocationAddress;
    }
  }

  ionViewDidEnter() {
    let that = this;

    this.globalVars.setPageLoaded('CreateUserPage',true);

    that.title = "Crie sua conta";

    that.displayPassField = (that.origin != Origin.MENU_EDIT_USER);
    that.completeRegistration = this.isCompleteRegistration();
    that.showUpdatePasswordButton = !that.displayPassField && (this.userObj && this.userObj.passwd != null && this.userObj.passwd != '');

    if (that.origin == Origin.MENU_EDIT_USER || that.origin == Origin.CART_PAGE_WHIT_FACEBOOK_LOGIN) {
      that.title = "Atualize seu cadastro";
    }

    if (that.origin == Origin.MENU_EDIT_USER && that.userObj) {
      that.editUser(that.userObj);
      that.showDeleteButton = true;
    }

    if ((that.origin == Origin.ENTRY_PAGE_FACEBOOK_LOGIN ||
      that.origin == Origin.CART_PAGE_WHIT_FACEBOOK_LOGIN) && that.userObj) {
      that.editUser(that.userObj);
    }

    that.storage.get(Util.ONE_SIGNAL_PLAYER_KEY).then((userId) => {
      that.oneSignalPlayerId = userId;
    });

    this.titleContent = (this.completeRegistration) ? "Complete seu cadastro para continuar." : "Preencha os campos a baixo para realizar seu cadastro um única vez.";
  }

  ionViewDidLeave() {
    this.events.publish(EventKey.CLOSE_CREATEUSER);
  }

  ionViewWillUnload() { }

  close() {
    if(this.globalVars.isVisibleSplitPane()){
      this.modalController.dismiss();
    }else{
      this.navCtrl.back();
    }
  }

  private isCompleteRegistration():boolean{
    return this.userObj && (!this.userObj.cpf || !this.userObj.celular || this.userObj.name.trim().split(" ").length <= 1);
  }

  private editUser(userObj: any) {
    let dataNascimento;

    if(userObj.dateBirth){
      dataNascimento = moment(Util.brDateToDate(userObj.dateBirth)).format('DD/MM/YYYY');
    }

    this.cpf = userObj.cpf;
    this.nome = userObj.name;
    this.sexo = userObj.gender;
    this.celular = userObj.celular;
    this.telefone = userObj.phoneNumber;
    this.email = userObj.email;
    this.dataNascimento = dataNascimento;
  }

  save() {

    let that = this;
    if (that.formIsValid()) {

      this.showWaitCreateUserMsg(true);

      if (that.origin == Origin.MENU_EDIT_USER ||
        that.origin == Origin.ENTRY_PAGE_FACEBOOK_LOGIN ||
        that.origin == Origin.CART_PAGE_WHIT_FACEBOOK_LOGIN) {
        this.validateForEdit((isValid)=>{
          if(isValid){
            that.saveUser();
          }else{
            this.showWaitCreateUserMsg(false);
            this.editUser(this.userObj);
            Util.showMenssage(that.alertController, "Os campos <strong>CPF</strong>, <strong>Nome</strong> e <strong>Telefone</strong> não podem ser mais alterados");
          }
        });
      } else {
        that.createUser();
      }
    }
  }

  private showWaitCreateUserMsg(show:boolean){
    this.displayForm = !show;
    this.displayWaitCreateUser = show;
  }

  private validateForEdit(callBack){

      if((this.cpf === this.userObj.cpf) && (this.nome === this.userObj.name) && (this.celular === this.userObj.celular)){
        callBack(true);
      }else{
        this.orderService.getAllOrdersByBuyerId(this.globalVars.getBuyerObj().id, (orderList)=>{
          callBack(!(orderList && orderList.length > 0));
        },(errorData)=>{
          callBack(false);
        });
      }
  }

  private saveUser() {
    let that = this;

    let userObjToSave: any = that.getUserJsonObj();
    userObjToSave.id = this.userObj.id;

    if (this.createForm.controls['senha'].value && this.createForm.controls['senha'].value.trim() != '') {
      userObjToSave.passwd = this.createForm.controls['senha'].value;
      that.userService.saveUserWithNewPassword(userObjToSave, (type, obj) => {
        this.saveResult(type, obj);
      });
    } else if (this.userObj) {
      userObjToSave.passwd = this.userObj.passwd;
      that.userService.saveUser(userObjToSave, (type, obj) => {
        this.saveResult(type, obj);
      });
    }
  }

  private saveResult(type, obj) {

    let that = this;

    if (type == 'sucess') {
      this.userService.storageLocalUser(obj);
      
      that.showMsgSucessFinish(true);

      setTimeout(() => {
        that.events.publish(EventKey.LOGIN, obj);
        that.navCtrl.navigateRoot('').then( ok => {
          if (that.origin == Origin.CART_PAGE_WHIT_FACEBOOK_LOGIN || Origin.ENTRY_PAGE_FACEBOOK_LOGIN) {

            that.events.publish(EventKey.LOGIN, obj);
            if (that.callBack) {
              that.callBack(obj);
            }

          } else {
            that.events.publish(EventKey.OPEN_HOME);
          }
        });
      }, 2000);
    } else {

      this.displayForm = true;
      this.displayWaitCreateUser = false;
      this.displayMsgSucessFinish = false;

      Util.showMenssage(this.alertController, "Não possível salvar seu cadastro verifique sua conexão com a internet e tente novamente.");
    }
  }

  private showMsgSucessFinish(show:boolean){
    this.displayForm = !show;
    this.displayWaitCreateUser = !show;
    this.displayMsgSucessFinish = show;
  }


  private createUser() {
    let that = this;
    
    that.userService.createUser(that.getUserJsonObj(), (type, obj) => {
      if (type == 'sucess') {

        if (that.orderObj) {
          that.orderObj.buyer = obj;
        }

        that.userService.storageLocalUser(obj);
        that.showMsgSucessFinish(true);
        that.finalizeCreateUser(obj);

        if (that.oneSignalPlayerId) {
          this.oneSiginalPlayerProvider.saveOneSignalPlayerIdForBuyer(that.oneSignalPlayerId, (type, data) => {
            if (type == 'sucess') {
              console.log("OneSigalPlayerId salvo com sucesso!!");
            } else {
              console.error(data);
            }
          });
        }

      } else {

        this.displayForm = true;
        this.displayWaitCreateUser = false;
        this.displayMsgSucessFinish = false;

        if (obj.data.errorMessage) {
          let msg = "<strong>CPF ou Email já cadastradado!</strong> <br> Utilize a opção <strong>Entrar</strong> do menu ou a opção <strong>Esqueci minha senha.</strong>";
          Util.showMenssage(that.alertController, msg);
        } else {
          Util.showMenssage(that.alertController, "Não foi possível realizar seu cadastro nesse momento. Tente novamente mais tarde!!");
        }
      }
    });
  }

  private finalizeCreateUser(obj: any) {
    let that = this;

    setTimeout(() => {

      if (that.origin == Origin.MENU) {
        that.events.publish(EventKey.LOGIN, obj);

        that.navCtrl.navigateBack('').then(callBack => {
          that.events.publish(EventKey.OPEN_HOME);
        });
      } else if (that.origin == Origin.CART_PAGE) {
        that.events.publish(EventKey.LOGIN, obj);

        that.navCtrl.back();
        if (that.callBack) {
          that.callBack(obj);
        }

      } else {
        that.events.publish(EventKey.LOGIN, obj);

        that.navCtrl.navigateBack('').then(() => {
          if (that.callBack) {
            that.callBack(obj);
          }
        });
      }

    }, 2000);
  }

  getUserJsonObj() {
    
    let dtnascimento = (this.dataNascimento) ? moment(Util.brDateToDate(this.dataNascimento)).format('DD/MM/YYYY') : null;
    let telefone = (this.createForm.controls['telefone'].value) ? this.createForm.controls['telefone'].value.replace(/\D+/g, '') : null;
    let addressList = (this.userObj) ? this.userObj.addresses : null;
    let buyerClientes = (this.userObj) ? this.userObj.buyerClientes : null;

    let userJsonObj = {
      name: this.createForm.controls['nome'].value,
      cpf: this.createForm.controls['cpf'].value.replace(/\D+/g, ''),
      celular: this.createForm.controls['celular'].value.replace(/\D+/g, ''),
      gender: this.sexo,
      typeBuyer: 'Varejo',
      email: this.createForm.controls['email'].value.toLowerCase(),
      phoneNumber: telefone,
      passwd: this.createForm.controls['senha'].value,
      dateBirth: dtnascimento,
      addresses: addressList,
      buyerClientes: buyerClientes
    }

    return userJsonObj;
  }



  formIsValid() {
    let that = this;

    if (that.createForm.valid) {

      let cpfString: string = this.createForm.controls['cpf'].value.replace(/\D+/g, '');
      let telefone: string = (this.createForm.controls['telefone'].value) ? this.createForm.controls['telefone'].value.replace(/\D+/g, '') : null;
      let celular: string = this.createForm.controls['celular'].value.replace(/\D+/g, '');

      if (!Util.cpfIsValid(cpfString)) {
        Util.showMenssage(that.alertController, "CPF Inválido");
        return false;
      } else if (that.createForm.controls['confirmarsenha'].value != that.createForm.controls['senha'].value) {
        Util.showMenssage(that.alertController, "Os campos <strong>Senha</strong> e <strong>Confirmar Senha</strong> Senha devem ser iguais.");
        return false;
      } else if (!this.completeRegistration &&
        this.origin != Origin.MENU_EDIT_USER &&
        (that.createForm.controls['confirmarsenha'].value == '' ||
          that.createForm.controls['senha'].value == '')) {

        Util.showMenssage(that.alertController, "Os campos <strong>Senha</strong> e <strong>Confirmar Senha</strong> devem ser informados");

        return false;
      } if (!this.completeRegistration && this.displayPassField &&
        (that.createForm.controls['confirmarsenha'].value == '' ||
          that.createForm.controls['senha'].value == '')) {

        Util.showMenssage(that.alertController, "Os campos <strong>Senha</strong> e <strong>Confirmar Senha</strong> devem ser informados.");

        return false;
      } else if (telefone && telefone != '' && telefone.length < 10) {
        Util.showMenssage(that.alertController, "Telefone informado não é válido.");
        return false;
      } else if (celular.length < 11) {
        Util.showMenssage(that.alertController, "Celular informado não é válido.");
        return false;
      } else if (this.nome.trim().split(" ").length <= 1) {
        Util.showMenssage(that.alertController, "Informe o nome <strong>completo</strong>.");
        return false;
      }else if(!Util.validateEmail(this.email)){
        Util.showMenssage(that.alertController, "Informe um <strong>email</strong> válido.");
        return false;
      }

      return true;
    } else {

      if (!that.createForm.controls['cpf'].valid) {
        Util.showMenssage(that.alertController, "O CPF tem que ser informado.");
      } else if (!that.createForm.controls['nome'].valid) {
        Util.showMenssage(that.alertController, "O Nome tem que ser informado.");
      } else if (!that.createForm.controls['sexo'].valid) {
        Util.showMenssage(that.alertController, "O Sexo tem que ser informado.");
      } else if (!that.createForm.controls['email'].valid) {
        Util.showMenssage(that.alertController, "O E-mail deve ser informado e deve ser válido.");
      } else if (!this.createForm.controls['celular'].valid) {
        Util.showMenssage(that.alertController, "Um número de Celular tem que ser informado e não pode conter menos que 10 e mais que 12 digitos.");
      } else if (!this.createForm.controls['dateBirth'].valid) {
        Util.showMenssage(that.alertController, "A data de nascimento deve ser informado.");
      } else if (!that.createForm.controls['senha'].valid || !this.createForm.controls['confirmarsenha'].valid) {

        let senha = this.createForm.controls['senha'].value;
        let confirmarSenha = this.createForm.controls['confirmarsenha'].value;

        if ((senha && senha.trim().length == 0) || (confirmarSenha && confirmarSenha.trim().length == 0)) {
          Util.showMenssage(that.alertController, "Os campos <strong>Senha</strong> e <strong>Confirmar Senha</strong> não podem ficar vazios.");
        } else {
          Util.showMenssage(that.alertController, "Os campos <strong>Senha</strong> e <strong>Confirmar Senha</strong> tem que ter pelomenos 4 caracteres.");
        }
      }

      return false;
    }

  }

  openNewAddressPage() {
    let that = this;

    let params: any = { userObj: this.userObj, origin: Origin.CART_PAGE };

    if (that.actualLocationAddress) {
      params.address = that.actualLocationAddress;
    }

    this.openAddressCreatePage(params);
  }

  private async openAddressCreatePage(params) {
    if (this.globalVars.isVisibleSplitPane()) {

      params.modal = true;
      this.navigationDataService.setData("AddressCreatePage",params);

      const modal = await this.modalController.create(
        {
          component: AddressCreatePage,
          mode: 'ios',
          cssClass: 'create-addresss-modal'
        }
      );

      modal.present();
    } else {
      this.navigationDataService.setData("AddressCreatePage",params);
      this.router.navigate(['/address-create']);
    }
  }

  openAddressPage() {
    this.navigationDataService.setData("AddressListPage",{ orderObj: this.orderObj, actualLocationAddress: this.actualLocationAddress });
    this.router.navigate(['/address-list']);
  }

  updatePass() {
    this.displayPassField = true;
    this.showUpdatePasswordButton = false;
  }

  getMenssage(): string {
    return (this.origin != Origin.MENU_EDIT_USER) ? "Cadastro Realizado com Sucesso!" : "Cadastro Atualizado Com Sucesso!";
  }

  changeCheckBox(event) {
    console.log("CheckBox: " + this.receiveInformation);
  }

  captchaResolved(response: string): void {
    let that = this;
    
    this.globalVars.setRecaptchaInfo(null);

    this.zone.run(() => {
        that.captchaPassed = true;
        that.globalVars.setRecaptchaInfo(response);
    });
  }

  async delete(){
    let that = this;

    const modal = await this.alertController.create({
      subHeader: 'Atenção',
      message: this.msgDeleteAccount,
      buttons: [
        {
          text: 'Não'
        },
        {
          text: 'Sim',
          handler: ()=>{
    
            that.ldgController.create({
              message: 'Excluindo conta...',
            }).then((loading)=>{
              loading.present();
              that.deleteAccount(loading);
            });

          }
        }
      ]
    });

    modal.present();
  }

  private async deleteAccount(loading){
    let that = this;

    this.userService.deleteUser(this.userObj.id,(type,obj)=>{
      if(type == 'sucess'){

        this.showDeleteAccountModalFinish(loading);

      }else{
        loading.dismiss();
      }
    });
  }

  private showDeleteAccountModalFinish(loading: any) {
    let that = this;

    this.alertController.create({
      subHeader: 'Aviso',
      message: 'Conta excluida com sucesso!!!',
      buttons: [{
        text: 'Ok',
        handler: () => {
          that.deleteAccountOkHandler();
          that.finilizeDeleteAccount();
        },
      }],
    }).then((alert) => {
      loading.dismiss();
      alert.present();
    });
  }

  private deleteAccountOkHandler(){
    this.userService.storageLocalUser(null);
    this.globalVars.setBuyerObj(null);
    this.userObj = null;
    this.events.publish(EventKey.LOGOUT);
  }

  private finilizeDeleteAccount(){
    this.navCtrl.navigateBack('').then(() => {
      if (this.callBack) {
        this.callBack(null);
      }
    });
  }
}
