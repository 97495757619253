import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { Platform } from '@ionic/angular';
import { GlobalVars } from 'src/app/util/global-vars';
import { Util } from 'src/app/util/util';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyMeService {

  private baseUrl: string;
  private authText: string;

  constructor(public http: HttpService, 
    public userService: UserService,
    public storage:StorageService,
    public platform: Platform, 
    public globalVars:GlobalVars) {

    this.baseUrl = this.globalVars.getBaseURL();
    this.authText = this.globalVars.getAuthText();
  }

  saveNotifyMe(notifyMeObj, callBack) {
      this.authText = this.globalVars.getAuthText();

      let config = {
        headers:{
          Authorization: 'Basic ' + btoa(this.authText)
        }
      };
      
      this.http.post(this.baseUrl+'/rs/crud/notifyMe', notifyMeObj, config, 
      (data)=>{
        callBack('sucess',data);
      }, 
      (error)=>{
        callBack('fail',error);
      });
  }

  deleteNotifyMe(notifyMeObj, callBack) {
    this.authText = this.globalVars.getAuthText();

      let config = {
        headers:{
          Authorization: 'Basic ' + btoa(this.authText)
        }
      };
      
      this.http.delete(this.baseUrl+ '/rs/crud/notifyMe/'+notifyMeObj.id, config, 
      (data)=>{
        callBack('sucess',data);
      }, 
      (error)=>{
        callBack('fail',error);
      });
  }

  getPedingNotifyMeList(buyerId:number,successCallback:any, errorCallback:any) {
    this.authText = this.globalVars.getAuthText();
    
    let config = {
      headers:{
        Authorization: 'Basic ' + btoa(this.authText)
      }
    };

    this.http.get(this.baseUrl +'/rs/crud/notifyMe/allPendingByBuyerId/'+buyerId, config, successCallback, errorCallback);
  }

  storageNotifyMeList(notifyMeList:any){
    let value:any = (notifyMeList)?JSON.stringify(notifyMeList):null;
    this.storage.set(Util.NOTIFY_ME_LIST_STORAGE_KEY,value);
  }

  getStoragedNotifyMeList(callBack:any){

    this.storage.get(Util.NOTIFY_ME_LIST_STORAGE_KEY).then((notifyMeList) => {

      if(notifyMeList){
        callBack(JSON.parse(notifyMeList));
      }else{
        callBack(null);
      }

    },(errorData)=>{
      callBack(null);
      console.error(JSON.parse(errorData));
    });
  }

  getSotoragedNotifyMeByProduct(productId:number,callBack){
    this.getStoragedNotifyMeList((notifyMeList)=>{
      if(notifyMeList){
        for(let notifyMe of notifyMeList){
          if(notifyMe.product.id == productId){
            callBack(notifyMe);
            return;
          }
        }
      }
      callBack(null);
    });
  }
}
