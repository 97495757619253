import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController, ModalOptions } from '@ionic/angular';
import { UsageStatisticsService } from 'src/app/services/usage-statistics.service';
import { UserService } from 'src/app/services/user.service';
import { EventKey } from 'src/app/util/event-key';
import { Events } from 'src/app/util/Events';
import { GlobalVars } from 'src/app/util/global-vars';
import { Route, Router } from '@angular/router';
import { Origin } from 'src/app/util/origin';
import { NavigationDataService } from 'src/app/services/navigation-data.service';
import { LoginPage } from '../login/login.page';

@Component({
  selector: 'app-wallet-info',
  templateUrl: './wallet-info.page.html',
  styleUrls: ['./wallet-info.page.scss'],
})
export class WalletInfoPage implements OnInit {

  @Input('show-header') showHeader:boolean = true;

  public userObj:any;

  constructor(public navCtrl: NavController,
    public modalController: ModalController, 
    public usageStatisticsProvider:UsageStatisticsService, 
    public userService:UserService, 
    public events:Events,
    public globalVars:GlobalVars,
    public router: Router,
    public navigationDataService:NavigationDataService) {
      
    usageStatisticsProvider.sendUsageStatistics("WalletInfoPage");

    this.userService.getStoragedLocalUser((userObj: any) => {
      if (userObj != null) {
        this.userObj = JSON.parse(userObj);
      } else {
        this.userObj = null;
      }
    });

    this.globalVars.setPageLoaded('BonusInfoPage',true);
  }

  ngOnInit() {
  }
  
  async authenticateUser(){

    if (this.userObj != null) {
      this.close();
      this.events.publish(EventKey.OPEN_BONUS);
    }else{
      let params: any = { origin: Origin.BONUS_INFO_PAGE, orderObj: null };

      let opts: ModalOptions = {
        component: LoginPage,
        mode: 'ios',
      };
  
      if (this.globalVars.isVisibleSplitPane()) {
        opts.cssClass = 'login-modal';
      }
  
      this.navigationDataService.setData("LoginPage", params);
  
      const modal = await this.modalController.create(opts);
      modal.present();
    }
  }

  close(){
    if(this.globalVars.isVisibleSplitPane()){
      this.modalController.dismiss();
    }else{
      this.navCtrl.back();
    }
  }

}
