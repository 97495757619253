import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor() {}

  getPromise(url,config):Promise<AxiosResponse<any,any>>{

    return new Promise(resolve => {
      axios.get(url, config).then(
        (res)=>{
          resolve(res.data);
        },
        (err)=>{
          console.error(err);
          resolve(null);
        }
      );
    });

  }

  get(url,config,successCallback,errorCallback){

    axios.get(url,config).then(
      (res) => {
        successCallback(res.data);
      }).catch(
      (err) => {
        errorCallback(err.response);
    });
  }

  post(url,data,config,successCallback,errorCallback){
    axios.post(url,data,config).then(
      (res) => {
        //let jsonData = JSON.parse(res['_body']);
        successCallback(res.data);
      }).catch(
      (err) => {
        //let jsonError = JSON.parse(err['_body']);
        errorCallback(err.response);
    });
  }

  delete(url,config,successCallback,errorCallback){
    axios.delete(url,config).then(
      (res) => {
        //let jsonData = JSON.parse(res['_body']);
        successCallback(res.data);
      }).catch(
      (err) => {
        //let jsonError = JSON.parse(err['_body']);
        errorCallback(err.response);
    });
  }
  
}
