import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./pages/product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'order-list',
    loadChildren: () => import('./pages/order-list/order-list.module').then( m => m.OrderListPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'create-user',
    loadChildren: () => import('./pages/create-user/create-user.module').then( m => m.CreateUserPageModule)
  },
  {
    path: 'address-list',
    loadChildren: () => import('./pages/address-list/address-list.module').then( m => m.AddressListPageModule)
  },
  {
    path: 'address-create',
    loadChildren: () => import('./pages/address-create/address-create.module').then( m => m.AddressCreatePageModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'delivery-payment-info',
    loadChildren: () => import('./pages/delivery-payment-info/delivery-payment-info.module').then( m => m.DeliveryPaymentInfoPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'about-app',
    loadChildren: () => import('./pages/about-app/about-app.module').then( m => m.AboutAppPageModule)
  },
  {
    path: 'wallet-info',
    loadChildren: () => import('./pages/wallet-info/wallet-info.module').then( m => m.WalletInfoPageModule)
  },
  {
    path: 'product-list',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'add-info',
    loadChildren: () => import('./pages/add-info/add-info.module').then( m => m.AddInfoPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'order-review',
    loadChildren: () => import('./pages/order-review/order-review.module').then( m => m.OrderReviewPageModule)
  },
  {
    path: 'type-payment',
    loadChildren: () => import('./pages/type-payment/type-payment.module').then( m => m.TypePaymentPageModule)
  },
  {
    path: 'payment-method',
    loadChildren: () => import('./pages/payment-method/payment-method.module').then( m => m.PaymentMethodPageModule)
  },
  {
    path: 'pix-payment-method',
    loadChildren: () => import('./pages/pix-payment-method/pix-payment-method.module').then( m => m.PixPaymentMethodPageModule)
  },
  {
    path: 'pag-seguro-payment-method',
    loadChildren: () => import('./pages/pag-seguro-payment-method/pag-seguro-payment-method.module').then( m => m.PagSeguroPaymentMethodPageModule)
  },
  {
    path: 'add-credit-card',
    loadChildren: () => import('./pages/add-credit-card/add-credit-card.module').then( m => m.AddCreditCardPageModule)
  },
  {
    path: 'rede-payment-method',
    loadChildren: () => import('./pages/rede-payment-method/rede-payment-method.module').then( m => m.RedePaymentMethodPageModule)
  },
  {
    path: 'html-content',
    loadChildren: () => import('./pages/html-content/html-content.module').then( m => m.HtmlContentPageModule)
  },
  {
    path: 'client-list',
    loadChildren: () => import('./pages/client-list/client-list.module').then( m => m.ClientListPageModule)
  },
  {
    path: 'update-version-info',
    loadChildren: () => import('./pages/update-version-info/update-version-info.module').then( m => m.UpdateVersionInfoPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
